//@ts-nocheck
import { useState } from "react";
import { useNavigate, Navigate, Link } from "react-router-dom";
import { PiEyeThin } from "react-icons/pi";
import styles from "styles/signupscreen.module.scss";
import { PlayaLogo } from "icons/PlayaLogo";
import { PasswordToggleIcon } from "icons/PasswordToggleIcon";
import { LoadingScreen } from "components/common/LoadingScreen";
import { errorSignature } from "utils/errorSignature";
import { UserError } from "components/common/UserError";
import { PrimaryButton } from "components/styles/Button";
import {
  useRegisterMutation,
  useCreateVerificationCodeMutation,
} from "features/auth/authApiSlice";

const SignupScreen = () => {
  const navaigate = useNavigate();

  const [register, { isLoading, isSuccess, error }] = useRegisterMutation();
  const [createVerificationCode] = useCreateVerificationCodeMutation();

  const defaultFormState = {
    userName: { value: "", error: null },
    emailAddress: { value: "", error: null },
    password: { value: "", error: null },
  };

  const [formState, setFormState] = useState(defaultFormState);
  const [isPassword, setIsPassword] = useState<boolean>(true);

  const onChangeHandler = (field, value) => {
    setFormState({
      ...formState,
      [field]: {
        value: value,
        error: null,
      },
    });
  };

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    let hasErrors = handleFormValidations();

    const updatedState = { ...formState };

    const { userName, emailAddress, password } = updatedState;

    const formData = {
      username: userName.value,
      email: emailAddress.value,
      password: password.value,
    };

    if (hasErrors) {
      return;
    }

    await register(formData)
      .unwrap()
      .then((payload) =>
        createVerificationCode({ email: formState.emailAddress.value }),
      )
      .catch((error) => console.log(error));
  };

  const handleFormValidations = () => {
    let updatedState = { ...formState };
    let error = false;

    const { userName, emailAddress, password } = updatedState;

    if (!userName.value) {
      updatedState.userName.error = "Enter a username";
      error = true;
    }
    if (userName.value && userName.value?.length < 3) {
      updatedState.userName.error = "username cannot be less than 3 characters";
      error = true;
    }

    const re =
      /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@(([^<>()[\]\\.,;:\s@\\"]+\.)+[^<>()[\]\\.,;:\s@\\"]{2,})$/i;

    if (!emailAddress?.value) {
      updatedState.emailAddress.error = "Enter email address";
      error = true;
    }

    if (emailAddress?.value && !emailAddress?.value?.match(re)) {
      updatedState.emailAddress.error = "Email address is invalid";
      error = true;
    }

    if (!password?.value) {
      updatedState.password.error = "Enter a password";
      error = true;
    }

    if (password?.value && password?.value?.length < 8) {
      updatedState.password.error = "password cannot be less than 8 characters";
      error = true;
    }

    setFormState({
      ...formState,
      ...updatedState,
    });
    return error;
  };

  // i do not need to pass the whole form state. I could have just passed the email value only
  if (isSuccess)
    return <Navigate to="/verifyaccount" replace={false} state={formState} />; 

  return (
    <section className={styles.signupScreenWrapper}>
      <div className={styles.signupCtaContainer}>
        <PlayaLogo />
        <h5>Connect with other music lovers</h5>
      </div>

      {isLoading && <LoadingScreen />}

      <div className={styles.signupScreenContainer}>
        <form onSubmit={handleSubmit}>
          <div className={styles.signupTextInputContainer}>
            <label htmlFor="username">Your username</label>
            <input
              value={formState.userName?.value}
              onChange={(e) => onChangeHandler("userName", e.target.value)}
              placeholder="username"
              type="text"
              id="username"
              className={`${
                formState.userName.error
                  ? `textInputErrorState`
                  : styles.signupTextInput
              } textInput`}
            />
            {(formState?.userName?.error || error) && (
              <UserError
                errorType={formState.userName.error}
                authenticationErrorType={errorSignature.USERNAME_ALREADY_EXIST}
              />
            )}
          </div>
          <div className={styles.signupTextInputContainer}>
            <label htmlFor="emailaddress">Email address</label>
            <input
              value={formState.emailAddress?.value}
              onChange={(e) => onChangeHandler("emailAddress", e.target.value)}
              placeholder="example@gmail.com"
              type="text"
              name="emailaddress"
              id="emailaddress"
              className={`${
                formState.emailAddress.error
                  ? `textInputErrorState`
                  : styles.signupTextInput
              } textInput`}
            />
            {(formState?.emailAddress?.error || error) && (
              <UserError
                errorType={formState.emailAddress.error}
                authenticationErrorType={errorSignature.EMAIL_ALREADY_EXIST}
              />
            )}
          </div>

          <div className={`${styles.signupTextInputContainer}`}>
            <label htmlFor="password">Choose a password</label>

            <div className={styles.passwordToggeWrapper}>
              <input
                value={formState.password?.value}
                onChange={(e) => onChangeHandler("password", e.target.value)}
                placeholder="min. 8 characters"
                type={isPassword ? "password" : "text"}
                id="password"
                className={`${
                  formState.password.error
                    ? `textInputErrorState`
                    : styles.signupTextInput
                } textInput`}
              />

              <div
                onClick={() => setIsPassword(!isPassword)}
                className={styles.passwordToggleIconWrapper}
              >
                {isPassword ? <PasswordToggleIcon /> : <PiEyeThin />}
              </div>
            </div>

            {formState?.password?.error && (
              <UserError errorType={formState.password.error} />
            )}
          </div>

          <div className={styles.signupButtonContainer}>
            <PrimaryButton kind="primary" type="submit">
              Sign up
            </PrimaryButton>
            <div>
              <div className={styles.signupLoginButtonContainer}>
                <span>Already have an account?</span>
                <span
                  className={styles.loginButton}
                  onClick={() => navaigate("/login")}
                >
                  Log in
                </span>
              </div>
            </div>
          </div>
        </form>

        <div className={styles.termsAndCondition}>
        <p>By clicking ‘Sign Up’ you are agreeing to our</p>
        <div className={styles.termsAndConditionFlex}>
          <Link to="/terms-of-service">Terms</Link>
          <p>and</p>
          <Link to="/privacy-policy">Privacy Policy</Link>
        </div>
      </div>
      </div>

      
    </section>
  );
};

export default SignupScreen;
