import { Layout } from 'components/Layout'
import { ScreenTitle } from 'components/ScreenTitle';
import { BackIcon } from 'icons/BackIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import styles from "styles/profilescreen.module.scss";

const TermsOfService = () => {
    return (
        <Layout>
            <div className={styles.profilescreenWrapper}>
                <AccountTitleContainer>
                    <ScreenTitle title='Terms of Service' icon={<BackIcon />}></ScreenTitle>
                </AccountTitleContainer>
            </div>

            <div style={{ padding: "20px 0", color: "#201E1E", fontWeight: "500", fontSize: "14px" }}>
                <p style={{ padding: "8px 0" }}>Terms of Service – Playa Interactive.</p>
                <p style={{ padding: "8px 0" }}>Please read these Terms of Service (“Terms”), which set forth the legally binding terms and conditions between you and Playa Interactive.  (‘Playa’ or ‘we’, ‘our’ or ‘us’). It governs your access to and the use of Playa’s website, mobile and other software applications (the “Platform”) and the services provided by Playa.</p>
                <p style={{ padding: "8px 0" }}>
                    Our collection and use of your personal information in connection with your use of our services is described in our <Link style={{ textDecoration: "underline", color: "blue" }} to="/privacy-policy">Privacy Policy.</Link>
                </p>
                <p style={{ padding: "8px 0" }}>
                    Your access to and use of our services is conditioned on your acceptance of and compliance with these Terms. These Terms apply to all visitors, users, agents, and any other person who accesses or uses our services.
                </p>
                <p style={{ padding: "8px 0" }}>
                    Our Platform is not directed at you if we are prohibited by any law of any jurisdiction from making the information on our website available to you and is not intended for any use that would be contrary to your local law or regulation.
                </p>
                <p style={{ padding: "8px 0" }}>
                    You agree that by accessing the services, you have read, understood, and agreed to be bound by these Terms. IF YOU DO NOT AGREE WITH THESE TERMS, THEN YOU ARE EXPRESSLY PROHIBITED FROM USING THE SERVICES AND YOU MUST DISCONTINUE USE IMMEDIATELY.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    1. Definitions
                </p>

                <p style={{ padding: "8px 0" }}>
                    In these Terms,
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Account"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means a unique personified account registered in the name of the User and which contains details of the User’s activities/operations on the Platform (as defined below).
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Music Streaming Services"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means any internet platform, mobile app, music platform, or other third-party music streaming services other than the Platform.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Playlist"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means music material, being a unique creative compilation of songs or pieces of music compiled by the User on specific Music Streaming Services.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "License"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means a license You grant to Playa to unleash the potential of the Platform
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Platform"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means, collectively the Company’s website, mobile application and other related software applications provided by Playa.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Services(s)"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means all products and services provided to you by Playa and as described in clause 4 of these Terms.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Site"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means the website for the services provided by Playa which can be found at <Link style={{ textDecoration: "underline", color: "blue" }} to="https://www.playamusic.io" target='_blank'>https://www.playamusic.io</Link>
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Terms"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means these standard terms of service.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "User"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means persons, customers and visitors who access the Service.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    2. Age Restriction
                </p>

                <p style={{ padding: "8px 0" }}>
                    Our Platform and Services are directed to people from the ages of 13 and above. By accessing or using our Platform or Services, you represent and warrant that you are 13 years old or older and have the legal capacity and authority to enter into a contract.
                </p>

                <p style={{ padding: "8px 0" }}>
                    Persons under 13 years old who intend to access the Service may do so under the supervision of an eligible guardian.
                </p>.

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    3. Acceptance of Terms
                </p>

                <p style={{ padding: "8px 0" }}>
                    The Service is offered subject to acceptance of all the terms and conditions contained in these Terms and all other operating rules, policies, and procedures that may be published on the Platform, which are incorporated by reference, including operating rules, policies, and procedures of third-party service providers to the Platform that are referenced herein. These Terms apply to every user of the Service. In addition, some Services offered through the Platform may be subject to additional terms and conditions adopted by the Platform. Your use of those Services is subject to those additional terms and conditions, which are incorporated into these Terms by this reference.
                </p>

                <p style={{ padding: "8px 0" }}>
                    Playa reserves the right, at its sole discretion, to modify or replace these Terms from time to time by posting the updated terms on the Platform. It is your responsibility to check the Terms periodically for changes. If you object to any such changes, your sole recourse will be to cease using the Platform and the Service.  Your continued use of the Service following the posting of any changes to the Terms will indicate your acknowledgement of such changes and agreement to be bound by the terms and conditions of such changes.
                </p>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    Playa reserves the right to change, suspend, or discontinue the Service (including, but not limited to, the availability of any feature, database, or Information) at any time for any reason. Playa may also impose limits on certain features and Services or restrict your access to parts of or the entire Platform without notice or liability.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    4. Scope of Playa’s Services
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>Playa is a comprehensive personal music management platform designed to enhance music streaming experiences.</li>
                    <li>Playa provides cross-platform copying that enables Users to copy songs and playlists across different music streaming platforms seamlessly.</li>
                    <li>Playa provides playlist sharing that allows Users to share playlists with others, regardless of the streaming services they use.</li>
                    <li>Playa provides playback and management that allows Users the ability to listen to playlists, preview songs, and manage their music library by adding or removing songs.</li>
                    <li>Playa provides music discovery services by utilizing AI-powered recommendations to help Users discover new music and curate personalised playlists.</li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    5. Accounts
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>To access our Services as a User, you are required to sign up on the Platform, create an account by providing your full name, email address, password, and any other information we require from you to process or create your Account.</li>
                    <li>You must provide accurate, current and complete information during the registration process and always keep your Account information up to date.</li>
                    <li>You are responsible for safeguarding the password that you use to access the Services on the Platform and for any activities or actions under your password.</li>
                    <li>You agree not to disclose your password to any third party. You must notify us immediately upon becoming aware of any breach of security or unauthorised use of your Account.</li>
                    <li>You may not use as a username, the name of another person or entity or a name that is not lawfully available for use, a name or trademark that is subject to any rights of another person or entity other than you without appropriate authorisation, or a name that is otherwise offensive, vulgar or obscene.</li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    6. Personal Information
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        You agree to grant Playa an irrevocable right to collect and use any information you provide us, or we collect from you, for the specific purpose of providing you with our Services.
                    </li>
                    <li>
                        You consent that we may disclose and or transfer your information to third parties or any other entity we deem necessary to perform our obligations to you under this Agreement.
                    </li>
                    <li>All other provisions regarding the use of your personal information are contained in our Privacy Policy.</li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    7. Playlist Transfer
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        The Platform allows you to save your Playlists, for instance, in an XLSX format, and transfer them between various Music Streaming Services. By using this feature, you confirm that you have read and accepted the terms and conditions of the respective Music Streaming Services and agree not to infringe upon any third-party rights. Note that transferring a Playlist involves only the arrangement of song titles, not the actual songs themselves.
                    </li>
                    <li>
                        Playlists are subject to the terms and conditions of the Music Streaming Services where they were originally created. We strongly recommend that you review the terms, conditions, and privacy policies of any Music Streaming Service you use or visit.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    8. License
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Grant of License: You grant Playa a perpetual, worldwide, non-exclusive, royalty-free license to use, publicly display, perform, index, distribute, translate, copy, modify, and commercially utilise copyrighted Playlists to the extent legally permissible. This includes transferring Playlists between music streaming services or using them for other services provided by Playa (the "License").
                    </li>
                    <li>
                        Scope of Use: As a licensee, Playa may distribute, promote, present, or sublicense the Playlists to third parties without restrictions, at its sole discretion. Playa is also authorised to modify, transform, archive, and create derivative works from the Playlists. This includes sharing or posting the Playlists or related derivative works on the Platform or across other Music Streaming Services in any format.
                    </li>
                    <li>Attribution: While Playa has the right to use your Playlists, it is not obligated to identify you as the author of the Playlists in any promotional, marketing, or other use cases unless explicitly specified by Playa.</li>
                    <li>Sublicensing: You grant Playa the right to sublicense the use of your Playlists to third parties, enabling them to use, publicly display, perform, distribute, translate, and commercially utilise your Playlists on the Platform, other Music Streaming Services, or any other medium, as permitted under applicable law.</li>
                    <li>Ownership and Moral Rights: You retain exclusive ownership of the copyrighted Playlists, and neither Playa nor its sublicensees acquire ownership of the Playlists, in whole or in part. To the extent permitted by law, you waive and agree not to enforce any moral rights or equivalent rights in connection with the Playlists.</li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    9. Users’ Representations & Warranties
                </p>

                <p style={{ padding: "8px 0", }}>Representations and warranties are statements and promises made by you to Playa, which we rely on as being accurate in our dealings with you. You make the following representations and warranties to us at the time of agreeing to these Terms and every time you use the Services;</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        you are over the age of 13 years and you are of sound mind and have the capacity to enter into a legally binding contract;
                    </li>
                    <li>
                        all personal information that you provide about yourself is accurate and true to the best of your knowledge; and
                    </li>
                    <li>
                        you are not breaching any applicable laws or regulations that are applicable to you.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>10. Intellectual Property </p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        You agree that Playa and its licensors or partners own all rights, title and interest in the Service and all materials provided by us in connection with the Service, including, without limitation, software, images, text, graphics, illustrations, logos, patents, trademarks, service marks, copyrights or photographs, (the "Playa Content"), and all intellectual property rights related to the Playa Content. Our trademarks may not be used in connection with any product or service without our prior written consent.
                    </li>
                    <li>
                        You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit Playa’s Information. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by Playa or its licensors, except for the licenses and rights expressly granted in these Terms.
                    </li>
                    <li>
                        Users shall not directly or indirectly: (i) decipher, decompile, disassemble, reverse engineer, or otherwise attempt to derive any source code or underlying ideas or algorithms of any part of the Service, except to the extent applicable laws specifically prohibit such restriction; (ii) modify, translate, or otherwise create derivative works of any part of the Service; or (iii) copy, rent, lease, distribute, or otherwise transfer any of the rights that you receive hereunder.
                    </li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>11. Mobile Application</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Playa shall make available Mobile Application(s) to access the Services via a mobile device.  To use the Mobile Application, you must have a mobile device that is compatible with the mobile service.
                    </li>
                    <li>
                        Playa does not warrant that the Mobile Application will be compatible with your mobile device.  Playa hereby grants to you a non-exclusive, non-transferable, revocable license to use an object code copy of the Mobile Application for one registered account on one mobile device owned or leased solely by you, for your personal use.
                    </li>
                    <li>
                        You may not: (i) modify, disassemble, decompile or reverse engineer the Mobile Application; (ii) rent, lease, loan, resell, sublicense, distribute or otherwise transfer the Mobile Application to any third-party or use the Mobile Application to provide time sharing or similar services for any third-party; (iii) make any copies of the Mobile Application; (iv) remove, circumvent, disable, damage or otherwise interfere with security-related features of the Mobile Application, features that prevent or restrict use or copying of any content accessible through the Mobile Application, or features that enforce limitations on use of the Mobile Application; or (v) delete the copyright and other proprietary rights notices on the Mobile Application.
                    </li>
                    <li>
                        You acknowledge that Playa may occasionally issue upgraded versions of the Mobile Application and may automatically electronically upgrade the version of the Mobile Application that you are using on your mobile device.  You consent to such automatic upgrading on your mobile device and agree that these Terms will apply to all such upgrades.
                    </li>
                    <li>
                        Standard data charges from your mobile network or internet service provider may apply to your use of the Mobile Application.
                    </li>
                    <li>
                        The following additional terms and conditions apply with respect to any Mobile Application that Playa provides to you designed for use on an Apple iOS-powered mobile device (an “iOS App”):
                        <ul style={{ listStyleType: "disc", paddingLeft: "20px", display: "flex", flexDirection: "column", gap: "6px" }}>
                            <li>You acknowledge that these Terms are between you and Playa only, and not with Apple, Inc. (“Apple”).</li>
                            <li>
                                Your use of Playa’s iOS App must comply with Apple’s current App Store Terms of Service.
                            </li>
                            <li>
                                Playa, and not Apple, is solely responsible for our iOS App and the Services and related content available on our iOS App. You acknowledge that Apple has no obligation to provide maintenance and support services with respect to our iOS App.  To the maximum extent permitted by applicable law, Apple will have no warranty obligation whatsoever with respect to our iOS App.
                            </li>
                            <li>
                                You agree that Playa, and not Apple, is responsible for addressing any claims by you or any third-party relating to our iOS App or your possession and/or use of our iOS App, including, but not limited to: (i) product liability claims; (ii) and claims arising under consumer protection or similar legislation, and all such claims are governed solely by these Terms and any law applicable to us as provider of the iOS App.
                            </li>
                            <li>
                                You agree that Playa, and not Apple, shall be responsible, to the extent required by these Terms, for the investigation, defense, settlement and discharge of any third-party intellectual property infringement claim related to our iOS App or your possession and use of our iOS App.
                            </li>
                        </ul>
                    </li>

                    <li>
                        The following additional terms and conditions apply with respect to any Mobile Application that Playa provides to you designed for use on an Android-powered mobile device (an “Android App”):
                        <ul style={{ listStyleType: "disc", paddingLeft: "20px", display: "flex", flexDirection: "column", gap: "6px" }}>
                            <li>
                                You acknowledge that these Terms are between you and Playa only, and not with Google, Inc. (“Google”).
                            </li>
                            <li>
                                Your use of Playa’s Android App must comply with Google’s current Android Market Terms of Service.
                            </li>
                            <li>
                                Google is only a provider of the Android Market where you obtained the Android App. Playa, and not Google, are solely responsible for Playa’s Android App and the Services and content available thereon.  Google has no obligation or liability to you with respect to Playa’s Android App or these Terms.
                            </li>
                            <li>
                                You acknowledge and agree that Google is a third-party beneficiary to the Terms as they relate to Playa’s Android App.
                            </li>
                        </ul>
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>12. Prohibited Activities</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        You shall not use the Platform for any purpose that is prohibited by these Terms. You are responsible for all your activity in connection with the Services. Violation of our rules may result in the termination and cancellation of your activity. You acknowledge and agree that we may terminate any Playa activity initiated at any time for any reason (including, but not limited to, our independent assessment or the receipt of claims or allegations from third parties or authorities).
                    </li>
                    <li>
                        Users shall not utilise the Platform or the Services for any illegal purpose.
                    </li>
                    <li>
                        You are solely responsible for compliance with all laws, rules, regulations, and tax obligations that may apply to your use of the Services. In connection with your use of the Platform, you will not and will not assist or enable others to:
                        <ul style={{ listStyleType: "disc", paddingLeft: "20px", display: "flex", flexDirection: "column", gap: "6px" }}>
                            <li>
                                breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms;
                            </li>
                            <li>
                                use the Platform for any commercial or other purposes that are not expressly permitted by these Terms or in a manner that falsely implies endorsement, partnership or otherwise misleads others as to your affiliation with Playa;
                            </li>
                            <li>
                                harm, stalk, harass or otherwise violate the rights of others in any way in your use of the Platform;
                            </li>
                            <li>impersonate any person or entity, including, but not limited to, a Playa official, forum leader, guide or host, or falsely state or otherwise misrepresent your affiliation with a person or entity;</li>
                            <li>
                                copy, store or otherwise access or use any information, including personally identifiable information about any other User, contained on the Platform in any way that is inconsistent with Playa’s <Link to="/privacy-policy" style={{ color: "blue", textDecoration: "underline" }}>Privacy Policy</Link> or these Terms or that otherwise violates the privacy rights of Users or third parties;
                            </li>
                            <li>use the Platform in connection with the distribution of unsolicited commercial messages ("spam");</li>
                            <li>intentionally or unintentionally violate any applicable local, state, national or international law;</li>
                            <li>discriminate against or harass anyone on the basis of race, tribe, origin, religion, gender, physical or mental disability, medical condition, marital status, or age, or otherwise engage in any abusive or disruptive behaviour;</li>
                            <li>use, display, mirror or frame the Platform, or any individual element within the Playa, Playa’s name, any trademark, logo or other proprietary information belonging to Playa, or the layout and design of any page or form contained on a page in the Platform, without Playa’s express written consent;</li>
                            <li>dilute, tarnish or otherwise harm Playa’s brand in any way, including, registering and/or using Playa’s or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domain names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to Playa’s domains, trademarks, taglines, promotional campaigns;</li>
                            <li>use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Platform for any purpose;</li>
                            <li>avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by Playa or any of Playa’s providers or any other third party to protect the Platform;</li>
                            <li>attempt to decipher, decompile, disassemble or reverse engineer any of the software used to provide the Platform;</li>
                            <li>take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Platform; </li>
                            <li>violate or infringe anyone else’s rights or otherwise cause harm to anyone.</li>
                        </ul>
                    </li>
                    <li>You acknowledge that Playa has no obligation to monitor the access to or use of the Platform by any User , but has the right to do so to (i) operate, secure and improve the Platform (including without limitation for fraud prevention, risk assessment, investigation and customer support purposes); (ii) ensure Users’ compliance with these Terms; (iii) comply with applicable law or the order or requirement of a court, law enforcement or other administrative agency or governmental body; or (iv) as otherwise set forth in these Terms. Users agree to cooperate with and assist Playa in good faith, and to provide Playa with such information and take such actions as may be reasonably requested by Playa with respect to any investigation undertaken by Playa or a representative of Playa regarding the use or abuse of the Platform.</li>
                    <li>If you feel that any User you interact with, whether online or in person, is acting or has acted inappropriately, including but not limited to anyone who (i) engages in offensive, violent or sexually inappropriate behaviour, (ii) you suspect of stealing from you, or (iii) engages in any other disturbing conduct, you should immediately report such person to the appropriate authorities and then to Playa by contacting us with your police station and report number (if available); provided that your report will not obligate us to take any action beyond that required by law (if any) or cause us to incur any liability to you.</li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>13. Links to Other Websites </p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Our Platform may contain links to third-party websites or services that are not owned or controlled by Playa.
                    </li>
                    <li>
                        Playa has no control over and assumes no responsibility for, the content, privacy policies, or practices of any third-party websites sites or services. You further acknowledge and agree that Playa shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with the use of or reliance on any such content, goods or services available on or through any such web sites or services.
                    </li>
                    <li>
                        We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>14. Third Party Terms of Service</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        <span style={{ fontWeight: 600 }}>Youtube</span>- By using Playa, You are agreeing to be bound by the <Link to="https://www.youtube.com/t/terms" target='_blank' style={{ color: "blue", textDecoration: "underline" }}> YouTube Terms Of Service</Link>. Playa uses the <Link to="https://developers.google.com/youtube/terms/api-services-terms-of-service" target='_blank' style={{ color: "blue", textDecoration: "underline" }}> YouTube API Services.</Link> Please also checkout <Link to="https://policies.google.com/privacy" target='_blank' style={{ color: "blue", textDecoration: "underline" }}> Google Privacy Policy.</Link> Playa uses YouTube user information to access, provide and display YouTube data on Playa. Playa will be able to retrieve user YouTube username, user private playlists, liked videos, uploaded videos and subscribed channels. User data’s are only used to provide Playa services and are not shared with any external service. You can always revoke Playa access to your data via the <Link to="https://security.google.com/settings/security/permissions" target='_blank' style={{ color: "blue", textDecoration: "underline" }}> Google security settings page </Link> and can also contact Playa with any questions or complaints.
                    </li>
                    <li>
                        <span style={{ fontWeight: 600 }}>Spotify</span> - By using Playa, You are agreeing to be bound by the <Link to="https://www.spotify.com/legal/end-user-agreement/" target='_blank' style={{ color: "blue", textDecoration: "underline" }}> Spotify Terms and Conditions of Use</Link>. Playa uses the <Link to="https://developer.spotify.com/terms/" target='_blank' style={{ color: "blue", textDecoration: "underline" }}>Spotify API Services</Link>. Playa uses Spotify user information to access, provide and display Spotify data’s on Playa. Playa will be able to retrieve user Spotify username, user private playlists, liked tracks, liked albums and liked artists. User data’s are only used to provide Playa services and are not shared with any external service. You can always revoke Playa access to your data via the <Link to="https://www.spotify.com/account/apps/" style={{ color: "blue", textDecoration: "underline" }} target='_blank'>Spotify</Link> Apps with access to your Spotify information page and can also contact Playa with any questions or complaints.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>15. Termination</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        We may terminate or suspend our Service to you immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach these Terms.
                    </li>
                    <li>
                        Upon termination, your right to use the Platform and our Service will immediately cease.
                    </li>
                    <li>
                        You may terminate your Account at any time by contacting us at <Link to="mailto:hello@playamusic.io" style={{ textDecoration: "underline", color: "blue" }}>[hello@playamusic.io]</Link>. You may also delete your account by following account deletion instructions within the Playa Mobile Application on Apple and Android devices, or by contacting us at <Link to="mailto:hello@playamusic.io" style={{ textDecoration: "underline", color: "blue" }}>[hello@playamusic.io]</Link>. We will endeavor to delete your account within 48 hours of receiving a request and after the deletion of your account, you will no longer be able to log into the Platform. In addition, we will delete any information associated with your Account, with the exception of information required for transactional records. You also understand and acknowledge that, if you delete your account, you will not be able to restore your account or any associated rewards or accrued Playa points prior to your deletion request by creating a new account. We may also terminate your account, or impose limits on or restrict access to parts or all of the Service at any time, without notice or liability.
                    </li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>16. Indemnity</p>
                <p style={{ padding: "8px 0" }}>You agree to indemnify and hold harmless Playa, its affiliates and subsidiaries, its officers, directors, employees and agents, against all liabilities, costs, expenses, damages and losses (including any direct, indirect or consequential losses, loss of profit, loss of reputation and all interest penalties and legal and other reasonable professional costs and expenses) suffered or incurred as a result of: </p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        your fraudulent or illegal use of the Services or the Platform;
                    </li>
                    <li>
                        your negligence or any default by you of any of these Terms;
                    </li>
                    <li>
                        any inaccurate or incomplete information that you have knowingly provided to us;
                    </li>
                    <li>
                        allowing any other person to access your account either with your permission or because of your failure to keep your username and password private;
                    </li>
                    <li>
                        any claim made against you for actual or alleged infringement of Playa’s Intellectual Property.
                    </li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>17. Limitation of Liability</p>
                <p style={{ padding: "8px 0" }}>
                    In no event shall Playa, its directors, employees, partners, agents, suppliers, or affiliates, be liable for any indirect, incidental, special, consequential or punitive damages, including without limitation, loss of profits, data, use, goodwill, or other intangible losses, resulting from:
                </p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        your use of the Platform or the Services or your inability to use the Platform or the Services;
                    </li>
                    <li>
                        any conduct or content of any third party on the Platform
                    </li>
                    <li>
                        any unauthorised access, use or alteration of your transmissions or content, whether based on warranty, contract, tort (including negligence) or any other legal theory, whether or not we have been informed of the possibility of such damage, and even if a remedy set forth herein is found to have failed of its essential purpose;
                    </li>
                    <li>
                        any legal proceedings between you and any third parties.
                    </li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>18. Disclaimer</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Your use of the Service is at your sole risk. The Platform is provided on an “AS IS” and “AS AVAILABLE” basis. The Platform is provided without warranties of any kind, whether express or implied, including, but not limited to, implied warranties of merchantability, fitness for a particular purpose, non-infringement or course of performance.
                    </li>
                    <li>
                        The Services provided by Playa are intended for informational purposes only. The content is not intended to constitute professional advice, including but not limited to commercial, industrial or financial advice.
                    </li>
                    <li>
                        Users acknowledge that the utilisation of the Service is at their own risk and discretion. Playa shall not be held liable for any direct, indirect, incidental, consequential, or punitive damages arising out of or in connection with the use or reliance on the Service.
                    </li>
                    <li>
                        Users should be aware that utilisation of Playa's Services and/or Information do not guarantee any specific outcomes as may be expected by the User.  All uses of the contents of the Platform, other than for personal uses, are prohibited.
                    </li>
                    <li>
                        Playa, its subsidiaries, affiliates, and its licensors do not warrant that a) the Platform will function uninterrupted, secure or available at any particular time or location; b) any errors or defects will be corrected; c) the Platform is free of viruses or other harmful components; or d) the results of using the Platform will meet your requirements.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>19. Governing Law</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        These Terms shall be governed and construed in accordance with the laws of the Federal Republic of Nigeria, without regard to its conflict of law provisions.
                    </li>
                    <li>
                        Our failure to enforce any right or provision of these Terms will not be considered a waiver of those rights. If any provision of these Terms is held to be invalid or unenforceable by a court, the remaining provisions of these Terms will remain in effect. These Terms constitute the entire agreement between us regarding our Service and supersede and replace any prior agreements we might have between us regarding the Service.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>20. Dispute Resolution</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Any disputes arising under or in connection with the validity, interpretation and performance of this Terms between you and Playa or between Playa and any third parties that cannot be resolved amicably by the parties through negotiation within 30 (thirty) days shall be resolved by Arbitration at the Lagos Court of Arbitration (LCA) before a single arbitrator in accordance with the Arbitration and Conciliation Act, 2023, Laws of the Federation of Nigeria.
                    </li>
                    <li>
                        The Parties shall endeavour in good faith to mutually agree on the selection of an arbitrator. If the Parties cannot mutually agree on the selection of an arbitrator within ten (10) days of the request, they shall apply to the LCA to appoint an arbitrator. Arbitration proceedings shall be conducted in Lagos. The arbitrator will be requested to render an award within ninety (90) days and to provide, in writing the reasoning for the award. The decision of any such arbitrator shall be final and binding on the parties.
                    </li>
                    <li>
                        Each party shall bear its cost in connection with the Arbitration and the arbitrator’s fees shall be split equally between both parties.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>21. Force Majeure</p>
                <p style={{ padding: "8px 0" }}>
                    Playa shall not be liable for failure to perform, or for delay in performing its obligations hereunder if such failure or delay shall be due to natural disasters, war, riot, civil commotion, weather, pandemics, epidemics, labour disputes, failure of sub-contractors or any other cause beyond its reasonable control and whether or not of a similar nature to the foregoing.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>22. Feedback</p>
                <p style={{ padding: "8px 0" }}>
                    We welcome and encourage you to provide feedback, comments and suggestions for improvements to Playa’s Platform or Services. You may submit your feedback by emailing us at <Link to="mailto:hello@playamusic.io" style={{ textDecoration: "underline", color: "blue" }}>[hello@playamusic.io].</Link>   Any feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting your feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.
                </p>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>23. Changes to Terms & Conditions</p>
                <p style={{ padding: "8px 0" }}>
                    Playa reserves the right, in its sole discretion, to change the Terms of Service. Playa encourages you to periodically review the Terms to stay informed of our updates.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>24. Contact Us</p>
                <p style={{ padding: "8px 0" }}>
                    If you have any questions about these Terms, please contact us at <Link to="mailto:hello@playamusic.io" style={{ textDecoration: "underline", color: "blue" }}>[hello@playamusic.io].</Link>
                    This document was last updated in December 2024.
                </p>
            </div>
        </Layout>
    )
}

export default TermsOfService

const AccountTitleContainer = styled.div`
  display: flex;
  position: relative;
`;
