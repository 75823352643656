import { useEffect, useState, useCallback } from "react";
import styled from "styled-components";
import { YouTubePlayer } from "./YoutubePlayer";
import {
  ShuffleIcon,
  PreviousIcon,
  PlayIcon,
  PauseIcon,
  NextIcon,
  PlayerIcon,
} from "icons";
import PlaceholderImg from "../assets/placeholder.png";
import ViewPlayListScreen from "screens/ViewPlayListScreen";
import { LoadingScreen } from "./common/LoadingScreen";
import { PlayaToast } from "utils";
import toast from "react-hot-toast";

export const WebplaybackYoutube = ({ isOwner, playlist, is_paused, title }) => {
  const [showPlaylist, setShowPlaylist] = useState(false);
  const [isPlaying, setIsplaying] = useState(true);
  const [player, setPlayer] = useState(null);
  const [songDetails, setSongDetails] = useState(null);
  const [isShuffled, setIsShuffled] = useState(false);
  const [originalPlaylist, setOriginalPlaylist] = useState([]);
  const [currentPlaylist, setCurrentPlaylist] = useState([]);

  // Shuffle array while keeping current song as first element
  const shuffleArray = useCallback((array, currentSongId) => {
    if (!array || array.length <= 1) return array;

    const arrayCopy = [...array];
    const currentIndex = arrayCopy.findIndex((id) => id === currentSongId);

    if (currentIndex === -1) return array;

    // Remove current song
    const [currentSong] = arrayCopy.splice(currentIndex, 1);

    // Fisher-Yates shuffle for remaining songs
    for (let i = arrayCopy.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayCopy[i], arrayCopy[j]] = [arrayCopy[j], arrayCopy[i]];
    }

    return [currentSong, ...arrayCopy];
  }, []);

  // Rearrange array to continue from current song
  const rearrangeFromCurrent = useCallback((array, currentSongId) => {
    if (!array || array.length <= 1) return array;

    const currentIndex = array.findIndex((id) => id === currentSongId);
    if (currentIndex === -1) return array;

    const firstPart = array.slice(currentIndex);
    const secondPart = array.slice(0, currentIndex);
    return [...firstPart, ...secondPart];
  }, []);

  // Initialize original playlist when player is ready
  useEffect(() => {
    if (player?.getPlaylist && originalPlaylist.length === 0) {
      const playlist = player.getPlaylist();
      if (playlist?.length) {
        setOriginalPlaylist(playlist);
        setCurrentPlaylist(playlist);
      }
    }
  }, [player, player?.getPlaylist]);


  

  // Handle shuffle/unshuffle based on isShuffled state
  useEffect(() => {
    if (
      !player?.getVideoData ||
      !player?.loadPlaylist ||
      !player?.getCurrentTime
    ) {
      console.log("no player record");

      return;
    }

    const currentVideoId = player.getVideoData().video_id;
    const currentTime = player.getCurrentTime();

    if (isShuffled) {
      // Shuffle the playlist
      const shuffledPlaylist = shuffleArray(originalPlaylist, currentVideoId);
      setCurrentPlaylist(shuffledPlaylist);
      player.loadPlaylist(shuffledPlaylist);
      player.seekTo(currentTime, true);
      PlayaToast.success({ msg: "Shuffle On!" });
    } else if (isShuffled === false) {
      // Check explicitly for false to avoid running on initial render
      // Revert to original order starting from current song
      const reorderedOriginal = rearrangeFromCurrent(
        originalPlaylist,
        currentVideoId
      );
      setCurrentPlaylist(reorderedOriginal);
      player.loadPlaylist(reorderedOriginal);
      player.seekTo(currentTime, true);
      PlayaToast.success({ msg: "Shuffle Off!" });
    }
  }, [
    isShuffled,
    player,
    originalPlaylist,
    shuffleArray,
    rearrangeFromCurrent,
  ]);

  const onPlay = () => {
    if (player?.playVideo) {
      player.playVideo();
      setIsplaying(true);
    }
  };

  const onPause = () => {
    if (player?.pauseVideo) {
      player.pauseVideo();
      setIsplaying(false);
    }
  };

  const onNext = () => {
    if (player?.nextVideo) {
      player.nextVideo();
    }
  };

  const onPrev = () => {
    if (player?.previousVideo) {
      player.previousVideo();
    }
  };

  return (
    <TrackContainer>
      <YouTubePlayer
        playlistId={playlist}
        player={player}
        isPlaying={isPlaying}
        title={title}
        setPlayer={setPlayer}
        setIsplaying={setIsplaying}
        setSongDetails={setSongDetails}
      />
      {songDetails ? (
        <>
          <TrackCoverContainer>
            {playlist ? (
              <img
                src={
                  songDetails?.video_id
                    ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                    : ""
                }
                alt="playlist cover"
                width={320}
                height={180}
              />
            ) : (
              <img src={PlaceholderImg} alt="playlist cover" />
            )}
          </TrackCoverContainer>
          {isOwner && (
            <PlayerControlsContainer>
              <div onClick={() => setIsShuffled(!isShuffled)}>
                <ShuffleIcon color={isShuffled ? "#008135" : null} />
              </div>
              <div onClick={player?.getPlaylistIndex() > 0 ? onPrev : null}>
                <PreviousIcon
                  color={player?.getPlaylistIndex() > 0 ? null : "#cccccc"}
                />
              </div>
              <div>
                {!isPlaying ? (
                  <div id="play-button" onClick={onPlay}>
                    <PlayIcon />
                  </div>
                ) : (
                  <div id="pause-button" onClick={onPause}>
                    <PauseIcon />
                  </div>
                )}
              </div>
              <div
                onClick={
                  currentPlaylist.length - 1 === player?.getPlaylistIndex()
                    ? null
                    : onNext
                }
              >
                <NextIcon
                  color={
                    currentPlaylist.length - 1 === player?.getPlaylistIndex()
                      ? "#cccccc"
                      : null
                  }
                />
              </div>
              <div onClick={() => setShowPlaylist(!showPlaylist)}>
                <PlayerIcon />
              </div>
            </PlayerControlsContainer>
          )}
          <TrackDescription>
            <h5>{songDetails?.title}</h5>
            <Artist>
              <h6>{songDetails?.author}</h6>
            </Artist>
          </TrackDescription>
          {showPlaylist && (
            <div
              style={{
                position: "absolute",
                zIndex: 100,
                top: 0,
                left: 0,
                backgroundColor: "#ffffff",
                width: "100%",
              }}
            >
              <ViewPlayListScreen
                current_track={{
                  album: {
                    images: [
                      {
                        url: songDetails?.video_id
                          ? `https://img.youtube.com/vi/${songDetails?.video_id}/0.jpg`
                          : "",
                      },
                    ],
                  },
                }}
                setShowPlaylist={setShowPlaylist}
              />
            </div>
          )}
        </>
      ) : (
        <LoadingScreen />
      )}
    </TrackContainer>
  );
};

const PlayerControlsContainer = styled.div`
  margin-top: 40px;
  margin-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TrackContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

const TrackCoverContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;

  img {
    border-radius: 14px;
    object-fit: cover;
    object-position: center;
  }
`;

const TrackDescription = styled.div`
  display: flex;
  flex-direction: column;

  h5 {
    color: #1f1f1f;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
  }

  h6 {
    color: #8c8c8c;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }
`;

const Artist = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
