import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import cookies from "js-cookie";
import styles from "styles/onboarding.module.scss";
import PlayaOnboardingImg from "assets/PlayaOnboardingImg.svg";

const OnboardingScreen = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Check for access token in localStorage or cookies
    const token = localStorage.getItem("playa_access_token") || cookies.get("playa_access_token");
    if (token) {
      navigate("/home");
    }
  }, [navigate]);

  const handleNextClick = () => {
    navigate("/home");
  };

  return (
    <div className={styles.onboardingWrapper}>
      <div>
        <img src={PlayaOnboardingImg} alt="Onboarding Illustration" />
      </div>
      <h5>Let The Music Flow</h5>
      <div className={styles.subTextContainer}>
        <p>Manage and share your music with</p>
        <p>friends across different platforms.</p>
      </div>
      <div className={styles.buttonWrapper}>
        <button
          className={styles.onboardingButton}
          onClick={handleNextClick}
        >
          Next
        </button>
      </div>
      <div className={styles.overlay}></div>
    </div>
  );
};

export default OnboardingScreen;
