import { Layout } from "components/Layout";
import RecentSearches from "components/RecentSearches";
import { SearchIcon } from "icons";
import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import PlaylistSearchResult from "components/PlaylistSearchResult";
import SongsSearchResult from "components/SongsSearchResult";
import {
  getAllUserYoutubePlaylists,
  GOOGLE_LOCALSTORAGE_VALUES,
  refreshGoogleToken,
} from "services/youtube";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import cookies from "js-cookie";
import { PRIMARY_STREAM_SERVICE } from "contants/primaryStreamingService";
import {
  fetchUserTracks,
  getCurrentUserPlaylists,
  hasTokenExpired,
  LOCALSTORAGE_VALUES,
  refreshToken,
} from "services/spotify";
import { MusicKitContext } from "components/MusicKitController";
import {
  useCreateAiPlaylistMutation,
  useRefreshMutation,
  useSearchAiSongsMutation,
} from "features/auth/authApiSlice";
import { IPlayLists, IYoutubePlayLists } from "@types";

const SearchScreen = () => {
  // const [isPresentlySearching, setIsPresentlySearching] = useState(true);
  const [isPresentlySearching, setIsPresentlySearching] = useState(false);
  const [activeSearchState, setActiveSearchState] = useState("Playlist");
  const [searchQuery, SetSearchQuery] = useState("");
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const context = useContext(MusicKitContext);
  const [applemusicPlaylists, setAppleMusicPlaylists] = useState<any>([]);
  const [refresh] = useRefreshMutation();
  const [appType, setAppType] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean | null>(true);
  const [youtubePlaylists, setYoutubePlaylists] =
    useState<IYoutubePlayLists[]>();
  const [playlists, setPlaylists] = useState<IPlayLists>();
  const [serchedPlaylist, setSearchPlaylist] = useState<any>([]);
  const [aiGeneratedPlaylist, setAiGeneratedPlaylist] = useState<any>([]);
  const [aiGeneratedSongs, setAiGeneratedSongs] = useState<any>([]);
  const [userSongs, setUserSongs] = useState<any>([]);
  const [searchedSongs, setSearchedSongs] = useState<any>();
  const [query, setQuery] = useState("");
  const [initialSearchPlaylist, setInitialSearchPlaylist] = useState<any>([]);
  const debounceTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isSearchingAiPlaylist, setIsSearchingAiPlaylist] = useState(false);

  const handleButtonClick = (searchState: string) => {
    setActiveSearchState(searchState);
  };

  const [createPlaylist] = useCreateAiPlaylistMutation();
  const [fetchAiSongs] = useSearchAiSongsMutation();

  const handleCreatePlaylist = async (genre: any) => {
    const data = await createPlaylist({ genre });
    setAiGeneratedPlaylist((prev: any) => [...prev, data]);
  };

  useEffect(() => {
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      getSpotifyPlaylists();
    }
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC) {
      getApplePlaylists();
    }
    if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      getYoutubePlaylistt();
    }
  }, []);

  const getYoutubePlaylistt = async () => {
    if (
      (GOOGLE_LOCALSTORAGE_VALUES.refreshToken !== "undefined" ||
        user?.googleRefreshToken) &&
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      const { status, playlists } = await getAllUserYoutubePlaylists();

      if (status === "success") {
        setAppType("youtube");
        setYoutubePlaylists(status === "success" ? playlists : []);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        refreshGoogleToken(user.googleRefreshToken);
      }
    }
  };

  const getApplePlaylists = async () => {
    const { data } = await context?.getPlaylists();
    setAppType("apple");
    setAppleMusicPlaylists(data);
    setIsLoading(false);
  };

  const getSpotifyPlaylists = async () => {
    const { status, data } = await getCurrentUserPlaylists();
    const res = await getCurrentUserPlaylists();
    if (res.status === 200) {
      setAppType("spotify");
      setPlaylists(data);
      setIsLoading(false);
    } else {
      setIsLoading(false);
      await refresh("");
      if (LOCALSTORAGE_VALUES.refreshToken && hasTokenExpired()) {
        refreshToken();
      }
    }
  };

  const handleSearch = (event: any) => {
    const newQuery = event.target.value.toLowerCase();
    setQuery(newQuery);
    setIsPresentlySearching(true);
  };

  const handleSearchAiTracks = async (genre: string) => {
    try {
      const data = await fetchAiSongs({ genre });
      setAiGeneratedSongs(data);
    } catch (error) {
      setAiGeneratedSongs([]);
    }
  };

  useEffect(() => {
    setIsPresentlySearching(true);
    if (searchQuery.length > 2) {
      SetSearchQuery(searchQuery);
      filterSonglist(searchQuery);
      filterPlayList(searchQuery);
    }
  }, [searchQuery]);

  const searchAiSongs = (query: string) => {
    setIsSearchingAiPlaylist(true);

    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }

    if (query.trim() === "") {
      setIsSearchingAiPlaylist(false);
      setAiGeneratedSongs([]);
      setAiGeneratedPlaylist([]);
      return;
    }

    // Set a new timeout for 5 seconds
    debounceTimeoutRef.current = setTimeout(() => {
      if (query.length > 2) {
        handleSearchAiTracks(query);
        handleCreatePlaylist(query);
      }
    }, 5000);
  };

  useEffect(() => {
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      setInitialSearchPlaylist(playlists?.items || []);
      setSearchedSongs(userSongs);
    } else if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      setInitialSearchPlaylist(youtubePlaylists || []);
    } else if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC
    ) {
      setInitialSearchPlaylist(applemusicPlaylists || []);
    }
  }, [playlists, userSongs, youtubePlaylists, applemusicPlaylists, user]);

  useEffect(() => {
    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      fetchUserSongs();
    }
  }, []);

  const fetchUserSongs = async () => {
    const userSongs = await fetchUserTracks();
    setUserSongs(userSongs);
  };

  const filterSonglist = (query: any) => {
    const filteredSongs = userSongs.filter(
      (song: any) =>
        song.name.toLowerCase().includes(query.toLowerCase()) ||
        song.artists[0].name.toLowerCase().includes(query.toLowerCase())
    );
    setSearchedSongs(filteredSongs);
    return filteredSongs;
  };

  const filterPlayList = (query: string) => {
    let filteredPlaylists = [];

    if (user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.SPOTIFY) {
      if (playlists?.items) {
        filteredPlaylists = playlists.items.filter((playlist) =>
          (playlist as { name: string }).name
            .toLowerCase()
            .includes(query.toLowerCase())
        );
      }
    } else if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.APPLE_MUSIC
    ) {
      if (applemusicPlaylists) {
        filteredPlaylists = applemusicPlaylists.filter((playlist: any) =>
          playlist?.attributes?.name.toLowerCase().includes(query.toLowerCase())
        );
      }
    } else if (
      user?.primaryStreamingService === PRIMARY_STREAM_SERVICE.YOUTUBE_MUSIC
    ) {
      if (youtubePlaylists) {
        filteredPlaylists = youtubePlaylists.filter((playlist: any) =>
          playlist?.snippet?.title.toLowerCase().includes(query.toLowerCase())
        );
      }
    }

    setSearchPlaylist(filteredPlaylists);
  };

  return (
    <Layout>
      <SearchScreenContainer>
        <h5>Search</h5>
        <SearchInputContainer onSubmit={handleSearch}>
          <SearchButton>
            <SearchIcon />
          </SearchButton>
          <SearchInput
            type="search"
            name="search"
            id="search"
            value={searchQuery}
            onChange={(event) => SetSearchQuery(event.target.value)}
            onKeyUp={(event: React.KeyboardEvent<HTMLInputElement>) => {
              const target = event.target as HTMLInputElement;
              searchAiSongs(target.value);
            }}
            placeholder="Search for playlist or song"
          />
        </SearchInputContainer>

        {isPresentlySearching ? (
          <SearchContentContainer>
            <SearchContentSelectOptions>
              <StyledButton
                active={activeSearchState === "Playlist"}
                onClick={() => handleButtonClick("Playlist")}
              >
                Playlist
              </StyledButton>
              <StyledButton
                active={activeSearchState === "Songs"}
                onClick={() => handleButtonClick("Songs")}
              >
                Songs
              </StyledButton>
            </SearchContentSelectOptions>
            {activeSearchState === "Playlist" && (
              <PlaylistSearchResult
                playlists={
                  searchQuery.length > 2
                    ? serchedPlaylist
                    : initialSearchPlaylist
                }
                isSearchingAiPlaylist={isSearchingAiPlaylist}
                aiPlaylists={aiGeneratedPlaylist}
              />
            )}
            {activeSearchState === "Songs" && (
              <SongsSearchResult
                userSongs={searchQuery.length > 2 ? searchedSongs : userSongs}
                aiSongs={aiGeneratedSongs}
              />
            )}
          </SearchContentContainer>
        ) : (
          <RecentSearches />
        )}
      </SearchScreenContainer>
    </Layout>
  );
};

export default SearchScreen;

const SearchScreenContainer = styled.section`
  position: relative;
  h5 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
  }
`;

const SearchInputContainer = styled.div`
  display: flex;
  border: 1px solid #bfbfbf;
  border-radius: 4px;
  outline: none;
  display: flex;
  align-items: center;
  padding-left: 20px;
`;

const SearchButton = styled.button`
  outline: none;
  border: none;
  background: transparent;
`;

const SearchInput = styled.input`
  border: none;
  padding: 12px 16px;
  outline: none;
  width: 100%;
  font-size: 14px;
`;

const SearchContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
`;

const SearchContentSelectOptions = styled.div`
  display: flex;
  gap: 8px;
`;

interface StyledButtonProps {
  active: boolean;
}

const StyledButton = styled.button<StyledButtonProps>`
  padding: 8px 16px;
  border-radius: 32px;
  color: ${({ active }) => (active ? "#FAFAFA" : "#375768")};
  background-color: ${({ active }) => (active ? "#008135" : "transparent")};
  border: solid 1px #5f7f90;
  font-family: Satoshi;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px;
  outline: none;
  cursor: pointer;
`;
