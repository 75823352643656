import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentToken } from "./authSlice";
import cookies from "js-cookie";
const RequireAuth = () => {
  const token = useSelector(selectCurrentToken);
  const tokenn = token === null ? cookies.get("playa_access_token") : token;

  const location = useLocation();

  return tokenn ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};
export default RequireAuth;