import { Layout } from 'components/Layout'
import { ScreenTitle } from 'components/ScreenTitle';
import { BackIcon } from 'icons/BackIcon';
import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import styles from "styles/profilescreen.module.scss";

const PrivacyPolicy = () => {
    return (
        <Layout>
            <div className={styles.profilescreenWrapper}>
                <AccountTitleContainer>
                    <ScreenTitle title='Privacy Policy' icon={<BackIcon />}></ScreenTitle>
                </AccountTitleContainer>
            </div>

            <div style={{ padding: "20px 0", color: "#201E1E", fontWeight: "500", fontSize: "14px" }}>
                <p style={{ padding: "8px 0" }}>Privacy Policy for Playa Interactive.</p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    1. Introduction
                </p>

                <p style={{ padding: "8px 0" }}>
                    <Link to="https://www.playamusic.io" style={{ textDecoration: "underline" }}>https://www.playamusic.io </Link> is provided by Playa Interactive.
                </p>

                <p style={{ padding: "8px 0" }}>
                    At Playa Interactive, we value your privacy, and we are committed to safeguarding your personal information. All personal data that you provide us will be protected and kept confidential among our affiliates, representatives, and privies.
                </p>

                <p style={{ padding: "8px 0" }}>
                    Throughout the website, the terms “we”, “us” and “our” refer to Playa.
                </p>

                <p style={{ padding: "8px 0" }}>
                    This Privacy Policy (“Policy”) explains how we collect, use, share and protect your personal data in connection with your relationship with us as a user or potential user. It applies to all our customers, potential customers, applicants, partners and every other person we hold information about.
                </p>


                <p style={{ padding: "8px 0" }}>
                    This Policy also sets out your rights and who you may contact for further information.
                </p>

                <p style={{ padding: "8px 0" }}>
                    You agree to this Privacy Policy by visiting our website or registering as a user on our mobile application and when you use our services.
                </p>

                <p style={{ padding: "8px 0" }}>
                    Your use of our services, and any dispute over privacy is subject to this Policy and our Terms of Service, including its applicable limitations on damages and the resolution of disputes. Our Terms of Service are incorporated by reference into this Policy.
                </p>

                <p style={{ padding: "8px 0" }}>
                    Our apps, website or services are not directed at you if we are prohibited by any law of any jurisdiction from making the information on our app or website available to you and is not intended for any use that would be contrary to local law or regulation.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    2. Definitions
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Consent"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means the consent of the data subject which must be a freely given, specific, informed, and unambiguous indication of the data subject’s wishes by which they (by a statement or by a clear affirmative action) signify their agreement to the processing of personal data relating to them;
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Data Controller"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means the natural or legal person or organisation which, alone or jointly with others, determines the purposes and means of the processing of personal data. For the purposes of this policy, Playa is the data controller of all personal data relating to data subjects;
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Data Processor"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means a person or organisation which processes personal data on behalf of a data controller. This includes Playa, its employees and third-party service providers;
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Data Protection Legislation"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means all applicable data protection and privacy laws including, but not limited to the Nigeria Data Protection Act 2023 (NDPA), the Nigeria Data Protection Regulation 2019 (NDPR), and any successor legislation and General Data Protection Regulation (GDPR);
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Data Subject"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means a living, identified, or identifiable individual about whom Playa holds personal data;
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Personal Data"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means any information relating to a data subject who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, identification number, location data, an online identifier, or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural, or social identity of that data subject;
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Personal Data Breach"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means a breach of security leading to the accidental or unlawful destruction, loss, alteration, unauthorised disclosure of, or access to, personal data transmitted, stored, or otherwise processed;
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Platform"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means, collectively the Company’s website, mobile application and other related applications provided by Playa.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    "Processing"
                </p>

                <p style={{ padding: "8px 0" }}>
                    means any operation or set of operations performed on personal data or sets of personal data, whether or not by automated means, such as collection, recording, organisation, structuring, storage, adaptation or alteration, retrieval, consultation, use, disclosure by transmission, dissemination or otherwise making available, alignment or combination, restriction, erasure or destruction.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    3. Acceptance of Terms
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Where processing of your personal data is based on consent, we shall obtain the requisite consent at the time of collection of the personal information. In this regard, you consent to the processing of your personal information when you access our website, Mobile Application (“App”) or use our services, content, features, technologies or functions offered on our website, App or other digital platforms. You can withdraw your consent at any time but such withdrawal will not affect the lawfulness of the processing of your data based on consent given before its withdrawal.
                    </li>
                    <li>
                        Where your personal data is to be processed for a different purpose that is incompatible with the purpose or purposes for which that personal data was originally collected that was not disclosed to you when you first provided your consent, we will obtain your consent to the new purpose or purposes.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    4. Age Restriction
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        You affirm that you are over 13 years old and have the right to contract in your own name, and that you have read the above authorisation and fully understand its contents.
                    </li>
                    <li>
                        If you are a parent or guardian and you are aware that your Children has shared with us personal data, please immediately contact us by sending an email at <Link to="mailto:hello@playamusic.io" style={{ textDecoration: "underline", color: "blue" }}>[hello@playamusic.io].</Link> If we become aware that we have collected personal data from children under the age of 13 without verification of parental consent, we take steps to remove that information from our servers and we will delete such information from our files as soon as reasonably practicable.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    5. Data Protection Principles
                </p>

                <p style={{ padding: "8px 0" }}>The Data Protection Legislation sets out the following principles with which anyone handling personal data must comply. We, our employees, agents and contractors comply with the following principles when collecting or processing your personal data. All personal data must be:</p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        processed lawfully, fairly, and in a transparent manner in relation to the data subject;
                    </li>
                    <li>
                        collected for specified, explicit, and legitimate purposes and not further processed in a manner that is incompatible with those purposes. Further processing for archiving purposes in the public interest, scientific or historical research purposes or statistical purposes shall not be considered to be incompatible with the initial purposes;
                    </li>
                    <li>
                        adequate, relevant, and limited to what is necessary in relation to the purposes for which it is processed;
                    </li>
                    <li>
                        accurate and, where necessary, kept up to date. Every reasonable step must be taken to ensure that personal data that is inaccurate, having regard to the purposes for which it is processed, is erased, or rectified without delay;
                    </li>
                    <li>
                        kept in a form which permits identification of data subjects for no longer than is necessary for the purposes for which the personal data is processed. Personal data may be stored for longer periods insofar as the personal data will be processed solely for archiving purposes in the public interest, scientific or historical research purposes, or statistical purposes, subject to implementation of the appropriate technical and organisational measures required by the Data Protection Legislation in order to safeguard the rights and freedoms of the data subject;
                    </li>
                    <li>
                        processed in a manner that ensures appropriate security of the personal data, including protection against unauthorised or unlawful processing and against accidental loss, destruction, or damage, using appropriate technical or organisational measures.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    6. Information We Collect.
                </p>

                <p style={{ padding: "8px 0" }}>
                    In providing our services to you, we collect certain personal and non-personal data about you. Our policy is to keep this information confidential and strictly safeguarded, and to use or disclose it only as needed to provide services to you, or as permitted or required by the Data Protection Legislation. We collect a variety of information from our users and visitors to our Platform in order to properly deliver the services to our users. The information we collect fall into the following categories:
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Information Collected Automatically: Whenever you visit our Platform, our web servers automatically collect non-personal information such as the domain name of the internet access provider, the internet protocol address used to connect the computer to the internet, the average time spent on our website, pages viewed, information searched for, access times, usage statistics about your interactions with the Platform, including information accessed, time spent on pages or the service, and other relevant statistics.
                    </li>
                    <li>
                        Information You Provide Us: If you provide us with personal information, by contacting us, or creating an account on our Platform, we collect the following personal information (for registering your account and other purposes):
                        <ul style={{ listStyleType: "lower-alpha", paddingLeft: "20px", display: "flex", flexDirection: "column", gap: "6px" }}>
                            <li>
                                Contact information;
                            </li>
                            <li>
                                Geolocation of the user;
                            </li>
                            <li>
                                Details of your search queries, saved items, and preferences for notifications,
                            </li>
                            <li>
                                Information obtained during interactions with us, such as contacting our customer care team, responding to surveys, taking part in promotional activities, gaining access to the prices won from points gathered;
                            </li>
                            <li>
                                Email preferences, including subscriptions to marketing communications;
                            </li>
                            <li>
                                Streaming data i.e streaming service access tokens, refresh tokens, token expiration and playlist, and
                            </li>
                            <li>
                                Any other information you provide to us.
                            </li>
                        </ul>
                    </li>
                    <li>Other Information We Collect: We may also collect information from you using cookies and other analytical tools especially when you use our products and services. More details are provided below in our section on Cookies.</li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    7. Using Your Personal Data
                </p>

                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        We primarily collect your personal data to ensure that we provide the most efficient services to you, monitor the use and improve our Platform and other legitimate interests. Your information will solely be used and disclosed for the following purposes:
                        <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                            <li>
                                To help us verify your identity;
                            </li>
                            <li>
                                To carry out our obligations ensuing from any contracts entered into between you and us;
                            </li>
                            <li>
                                To provide you with the products, services and information you request from us and we offer to you;
                            </li>
                            <li>
                                To assist you with enquiries and improve our customer service;
                            </li>
                            <li>
                                To carry out marketing and advertising, including to send you marketing and advertising communications tailored to our services and your specific usage;
                            </li>
                            <li>
                                To assist us in carrying out marketing analysis and customer profiling, (conduct research, including creating statistical, geolocational and testing information;
                            </li>
                            <li>
                                To analyse the accuracy, effectiveness, usability, or popularity of the service(s);
                            </li>
                            <li>
                                To generate and review reports and data about our user base and service usage patterns;
                            </li>
                            <li>To facilitate the Platform’s technical functioning, including troubleshooting and resolving issues, securing the services, and preventing fraud and abuse;</li>
                            <li>To allow us to communicate with you in any way (including e-mail, telephone, and text or multimedia messages);</li>
                            <li>For our user reward program purposes;</li>
                            <li>To help prevent and detect loss;</li>
                            <li>To update our records;</li>
                            <li>To make recommendations and suggestions to you about services offered by us unless you have previously asked us not to do so;</li>
                            <li>To send you service or support messages, such as updates, security alerts, email notifications and /or newsletters;</li>
                            <li>To conduct investigations and risk assessments; </li>
                            <li>As required or permitted by law;</li>
                            <li>For compliance with legal and regulatory obligations; and</li>
                            <li>As we, in our sole discretion, otherwise determine to be necessary to ensure the safety or integrity of our users, employees, the public, or our services.</li>
                        </ul>
                    </li>
                    <li>
                        Employees, agents, contractors, or other parties working on behalf of Playa shall collect your personal data only to the extent required for the performance of their job duties and only in accordance with this Policy.
                    </li>
                    <li>
                        Employees, agents, contractors, or other parties working on behalf of Playa shall process your personal data only when the performance of their job duties requires it. Your personal data held by Playa cannot be processed for any unrelated reasons.
                    </li>
                </ul>




                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    8. Data Accuracy
                </p>

                <p style={{ padding: "8px 0" }}>Your personal data must be accurate and kept up to date. In this regard, Playa shall ensure that any data it collects and/or processes is accurate and not misleading in a way that could be harmful to you; make efforts to keep your personal data updated where reasonable and applicable; and make timely efforts to correct or erase your personal data when inaccuracies are discovered.</p>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>
                    9. Data Confidentiality
                </p>

                <p style={{ padding: "8px 0", }}>
                    Your information is regarded as confidential and will not be divulged to any third party, except under legal and/or regulatory conditions. You have the right to request copies of any and all information we keep on you, if such requests are made in compliance with applicable laws and other relevant enactments. While we are responsible for safeguarding the information entrusted to us, your role in fulfilling confidentiality duties includes, but is not limited to, adopting and enforcing appropriate security measures such as non-sharing of passwords and other platform login details, adherence with physical security protocols on our premises, dealing with only authorised officers of Playa.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>10. Disclosures</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        We will not sell, publish, or disclose to third parties your personal data collected on our website, through our servers or otherwise obtained by us, other than to provide our services and as set forth in this Policy. We may share generic aggregated demographic information not linked to any personally identifiable information regarding visitors and users with our business partners, trusted affiliates, professional advisers and advertisers for the purposes outlined above. We may share your information with these third parties for those limited purposes if you have given us your permission and in compliance with the Data Protection Legislation.
                    </li>
                    <li>
                        We may request and provide information about you from and to third parties to provide our services.
                    </li>
                    <li>
                        We will notify you as soon as we become aware of a harmful data breach which may result in a risk of your rights and freedom.
                    </li>
                    <li>You have the right to request an erasure of your data at any time.</li>
                    <li>We will notify you if we are transferring your data.</li>
                    <li>You may request at any time that we halt further dissemination of your data or cease to use your data.</li>
                    <li>If you submit content in a public forum or a social media post, or use a similar feature on our website, that content is publicly visible.</li>
                    <li>We may disclose personally identifiable information if required to do so by law or in the good faith belief that such action is necessary to (a) conform with the requirements of the law or comply with legal process served on us, or (b) act in urgent circumstances to protect the personal safety of users of our service or members of the public.</li>
                    <li>To the extent practicable and legally permitted, we will attempt to advise you prior to any such disclosure, so that you may seek a protective order or other relief limiting such disclosure.</li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>11. Transfer of Personal Data</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        <p style={{ fontWeight: 600 }}> Third Party Processor </p>

                        We may engage the services of third parties in order to process your personal data. The processing by such third parties shall be governed by a written contract with Playa to ensure adequate protection and security measures are put in place by the third party for the protection of your personal data in accordance with the terms of this policy and the Data Protection Regulation.

                    </li>
                    <li>
                        <p style={{ fontWeight: 600 }}>International Transfers</p>
                        Your information may be transferred to a foreign country or international organisation for the purpose of providing our service to you. We will ensure that there are adequate data protection laws in the recipient country or organisation before transferring your information. In particular, we shall, among other things, conduct a detailed assessment of whether the said country is on the Nigeria Data Protection Commission (NDPC) Whitelist of Countries with adequate data protection laws or any other relevant authorities that exist and may come into existence in the jurisdictions where the company has offices.
                        <p style={{ paddingLeft: 10 }}>- Transfer of your personal data out of Nigeria would be in accordance with the provisions of the NDPA. We will therefore only transfer your personal data out of Nigeria on one of the following conditions:</p>
                        <ul style={{ listStyleType: "disc", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                            <li>
                                Your explicit consent has been obtained;
                            </li>
                            <li>
                                The transfer is necessary for the performance of a contract between you and Playa;
                            </li>
                            <li>
                                The transfer is necessary to conclude a contract between Playa and a third party in your interest;
                            </li>
                            <li>The transfer is necessary for reason of public interest;</li>
                            <li>The transfer is for the establishment, exercise or defense of legal claims;</li>
                            <li>The transfer is necessary in order to protect your vital interests or the interests of other persons, where you are physically or legally incapable of giving consent.</li>
                        </ul>
                    </li>
                    <li>Provided, in all circumstances, that you have been manifestly made to understand through clear warnings of the specific principle(s) of data protection that are likely to be violated in the event of transfer to a third country, this provision shall not apply to any instance where you are answerable in duly established legal action for any civil or criminal claim in another country.</li>
                    <li>We will take all necessary steps to ensure that your personal data is transmitted in a safe and secure manner. Details of the protection given to your information when it is transferred outside Nigeria shall be provided to you upon request.</li>
                    <li>Where none of the conditions stipulated in clause 11.2.1 and11.2.2 of this policy are met, Playa will engage with NDPC for approval with respect to such transfer.</li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>12. Your Rights </p>
                <p style={{ padding: "8px 0" }}>Subject to certain limitations and exceptions, you are entitled to the following principal rights under the Data Protection Legislation:</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        You have the right to be notified if we are transferring your personal information.
                    </li>
                    <li>
                        You have the right to request an erasure of your personal data at any time.
                    </li>
                    <li>
                        You have the right to request that we rectify inaccurate personal information.
                    </li>
                    <li>
                        You may request at any time that we halt further dissemination of your data or cease to use your personal information.
                    </li>
                    <li>
                        You have the right to request for copies of your personal information.
                    </li>
                    <li>
                        You can opt out of marketing or advertising emails by following the unsubscribe process included in the promotional email you received or by logging into your account and indicating your preference. You may also request to opt out of marketing or advertising emails by contacting us at <Link to="mailto:hello@playamusic.io" style={{ textDecoration: "underline", color: "blue" }}>[hello@playamusic.io].</Link> Please keep in mind that you if you opt out of receiving marketing messages, you will still receive service-related emails from us (such as emails related to your requests or searches, your account on our Platform, correction of personal information, password reset requests, reminder emails you have requested, updates to our Terms of Service and policies and other similar communications essential to your transactions on the Platform) that may be necessary for us to make the Platform available to you or respond to your inquiries.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>13. Training</p>
                <p style={{ padding: "8px 0" }}>
                    We shall ensure that employees who collect, access and process your personal data receive adequate data privacy and protection training in order to develop the necessary knowledge, skills and competence required to effectively manage the compliance framework under this policy and the Data Protection Legislation with regard to the protection of personal data. On an annual basis, we shall develop a capacity building plan for our employees on data privacy and protection in accordance with the Data Protection Legislation.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>14. Use of Cookies</p>
                <p style={{ padding: "8px 0" }}>
                    We use cookies to identify you as a user and make your user experience easier, customise our services, content and advertising; help you ensure that your account security is not compromised, mitigate risk and prevent fraud; and to promote trust and safety on our website. Cookies allow our servers to remember your account log-in information when you visit our website, IP addresses, date and time of visits, monitor web traffic and prevent fraudulent activities. If your browser or browser add-on permits, you have the choice to disable cookies on our website; however, this may limit your ability to use our website.
                </p>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>15. The Data We Retain</p>
                <p style={{ padding: "8px 0" }}>
                    We will retain your information for as long as needed to provide you with our services, comply with our legal and statutory obligations or verify your information with a financial institution.
                    We are statutory obligated to retain the data you provide us with in order to process requests, ensure settlements, identify fraud and in compliance with laws and regulatory guidelines applicable to us.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>16. Data Breach Management Procedure </p>
                <p style={{ padding: "8px 0" }}>
                    In the event where there is any accidental or unlawful destruction, processing, loss, alteration, unauthorized disclosure of, or access to your personal data, we shall:
                </p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        notify you within 24 hours of the occurrence of the data breach;
                    </li>
                    <li>
                        properly investigate the breach and take the necessary steps to mitigate such breach;
                    </li>
                    <li>
                        identify remediation requirements and track the resolution of such breach; and
                    </li>
                    <li>
                        notify the NDPC and/or any other regulatory authority, where necessary.
                    </li>
                </ul>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>17. Links to Third Party Websites</p>
                <ul style={{ listStyleType: "lower-alpha", paddingLeft: "32px", display: "flex", flexDirection: "column", gap: "6px" }}>
                    <li>
                        Our Platform may contain links to third-party websites or services that are not owned or controlled by us.
                    </li>
                    <li>
                        We have no control over, and assume no responsibility for, the content, privacy policies, or practices of any third-party websites or services. You further acknowledge and agree that we shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such websites or services.
                    </li>
                    <li>
                        We strongly advise you to read the terms and conditions and privacy policies of any third-party websites or services that you visit.
                    </li>
                </ul>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>18. External services</p>
                <p style={{ padding: "8px 0" }}>
                    Playa uses YouTube API Services to facilitate transfer of playlists to and from YouTube. Please read Google Privacy policy before authenticating your YouTube account as Playa uses and transfer to any other app of information received from Google APIs will adhere to Google API Services User Data Policy, including the Limited Use requirements.
                </p>

                <p style={{ padding: "8px 0" }}>
                    Playa uses YouTube user information to access, provide and display YouTube data on Playa. Playa will be able to retrieve user YouTube username, user private playlists, liked videos, uploaded videos and subscribed channels. User data are only used to provide Playa services and are not shared with any external service. You can always revoke Playa access to your data via the Google security settings page and can also contact Playa with any questions or complaint.
                </p>

                <p style={{ padding: "8px 0" }}>
                    Playa uses the Spotify API Services. Please also checkout Spotify Privacy Policy. Playa uses Spotify user information to access, provide and display Spotify data on Playa. Playa will be able to retrieve user Spotify username, user private playlists, liked tracks, liked albums and liked artists. User datas are only used to provide Playa services and are not shared with any external service. You can always revoke Playa access to your data via the Spotify Apps with access to your Spotify information page and can also contact Playa with any questions or complaints.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>19. Limitation of Liability </p>
                <p style={{ padding: "8px 0" }}>
                    We exercise reasonable efforts to safeguard the security and confidentiality of your personal data; however, we will not be liable for unauthorised disclosure of personal data that occurs through no fault of ours.
                </p>


                <p style={{ padding: "8px 0", fontWeight: 600 }}>20. Changes to this Privacy Policy</p>
                <p style={{ padding: "8px 0" }}>
                    Changes may be made to this Privacy Policy from time. Whenever such changes are made, we will notify you. These changes will take effect immediately after you have been notified.
                </p>

                <p style={{ padding: "8px 0", fontWeight: 600 }}>21. Contact Us</p>
                <p style={{ padding: "8px 0" }}>
                    If you would like more information or you have any comments or questions on our Privacy Policy, please contact us at <Link to="mailto:hello@playamusic.io" style={{ textDecoration: "underline", color: "blue" }}>[hello@playamusic.io].</Link>
                </p>

                <p style={{ padding: "12px 0", fontWeight: 600 }}>This policy is effective as of December 2024.</p>
                <p style={{ padding: "4px 0", fontWeight: 600 }}>Last updated: December 2024.</p>
            </div>
        </Layout>
    )
}

export default PrivacyPolicy

const AccountTitleContainer = styled.div`
  display: flex;
  position: relative;
`;
