import { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
// import { accessToken } from "services/spotify";
import {
  LOCALSTORAGE_VALUES,
  getAccessToken,
  hasTokenExpired,
  refreshToken,
} from "services/spotify";
import {
  GOOGLE_LOCALSTORAGE_VALUES,
  getYoutubeAccessToken,
  hasGoogleTokenExpired,
  refreshGoogleToken,
} from "services/youtube";
import AccountVerificationScreen from "screens/AccountVerificationScreen";
import ForgetPasswordScreen from "screens/ForgetPasswordScreen";
import OtpVerificationScreen from "screens/OtpVerificationScreen";
import ConfirmPasswordScreen from "screens/ConfirmPasswordScreen";
import ConnectServiceScreen from "screens/ConnectServiceScreen";
import ConnectAccountsScreen from "screens/ConnectedAccountsScreen";
import OnboardingScreen from "screens/OnboardingScreen";
import SignupScreen from "screens/SignupScreen";
import AccountScreen from "screens/AccountScreen";
import LoginScreen from "screens/LoginScreen";
import HomeScreen from "screens/HomeScreen";
import SearchScreen from "screens/SearchScreen";
import PublicProfileScreen from "screens/PublicProfileScreen";
import RoomOwnerScreen from "screens/RoomOwnerScreen";
import ViewPlayListScreen from "screens/ViewPlayListScreen";
import ListeningRoomScreen from "screens/ListeningRoomScreen";
import NotificationScreen from "screens/NotificationScreen";
import NewroomScreen from "screens/NewroomScreen";
import ManagePlayListScreen from "screens/ManagePlayListScreen";
import ProfileScreen from "screens/ProfileScreen";
import SubscriptionScreen from "screens/SubscriptionScreen";
import ChangePasswordScreen from "screens/ChangePasswordScreen";
import RequireAuth from "features/auth/RequireAuth";
import PersistLogin from "features/auth/PersistLogin";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { Helmet } from "react-helmet";
import NewUserConnectAccount from "screens/NewUserConnectAccount";
import ViewSharedPlaylist from "screens/ViewSharedPlaylist";
import AIRoomScreen from "screens/AiListenScreen";
import AiCuiratedPlaylist from "screens/AiCuratedPlaylist";
import AiScreen from "components/Home/AiScreen";
import SearchedAiCuiratedPlaylist from "screens/ViewSerachedAiScreen";
import TermsOfService from "screens/TermsOfService";
import PrivacyPolicy from "screens/PrivacyPolicy";

function App() {
  const [token, setToken] = useState<string | null>(null);
  const [googleToken, setGoogleToken] = useState<string | null>(null);
  const userr = useSelector(selectCurrentUser);

  // useEffect(() => {
  //   setToken(accessToken);
  // }, []);

  // useEffect(() => {
  //   const interval = setInterval(
  //     () => {
  //       refreshToken();
  //       refreshGoogleToken(userr?.googleRefreshToken);
  //     },
  //     30 * 60 * 1000
  //   );

    // if (LOCALSTORAGE_VALUES.refreshToken && hasTokenExpired()) {
    //   refreshToken();
    //   console.log("getting new spotify token....");
    // }

  //   if (GOOGLE_LOCALSTORAGE_VALUES.refreshToken && hasGoogleTokenExpired()) {
  //     refreshGoogleToken(userr.googleRefreshToken);
  //     console.log("getting new youtube token....");
  //   }   

  //   return () => clearInterval(interval);
  // }, []);

  useEffect(() => {
    if(!token){
    const accessToken = getAccessToken();
    setToken(accessToken);
    }
  }, [token]);

  // useEffect(() => {
  //       localStorage.setItem("youtube_refresh_token", userr.googleRefreshToken);
  // }, [])

  const fetchGoogleToken = async () => {
    try {
      const { refreshToken, accessToken } = GOOGLE_LOCALSTORAGE_VALUES;
  
      if (!refreshToken && !accessToken) {
        await getYoutubeAccessToken();
      }
    } catch (error) {
      console.error("Error fetching Google access token:", error);
    }
  };
  
  useEffect(() => {
    fetchGoogleToken();
  }, []);

  return (
    <>
      <Helmet>
        <title>Playa: Let The Music Flow</title>
        <meta property="og:title" content="Playa: Let The Music Flow" />
        <meta
          property="og:description"
          content="Connect, manage, and share your music with friends, across streaming platforms."
        />
        <meta property="og:image" content="URL_TO_IMAGE" />
        <meta property="og:url" content="https://www.app.playamusic.io/" />
      </Helmet>
      <Routes>
        <Route path="/" element={<OnboardingScreen />} />
        <Route path="/signup" element={<SignupScreen />} />
        <Route path="/login" element={<LoginScreen />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/forgetpassword" element={<ForgetPasswordScreen />} />
        <Route path="/otpverification" element={<OtpVerificationScreen />} />
        <Route path="/confirmpassword" element={<ConfirmPasswordScreen />} />
        <Route path="/verifyaccount" element={<AccountVerificationScreen />} />
        <Route path="/changepassword" element={<ChangePasswordScreen />} />

        {/* protected routes */}
        {/* @ts-ignore */}
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="/home" element={<HomeScreen />} />
            <Route path="/account" element={<AccountScreen />} />
            <Route path="/connectservices" element={<ConnectServiceScreen />} />
            <Route
              path="/connectaccounts"
              element={<ConnectAccountsScreen />}
            />
            <Route
              path="/new-user-connect-accounts"
              element={<NewUserConnectAccount />}
            />
            <Route path="/search" element={<SearchScreen />} />
            <Route
              path="/publicprofile/:user"
              element={<PublicProfileScreen />}
            />
            {/* <Route path="/roomowner" element={<RoomOwnerScreen />} /> */}
            {/* <Route
            path="/viewplaylist/:roomId"
            element={<ViewPlayListScreen />}
          /> */}
            <Route
              path="/listeningroom/:playlistId"
              element={<ListeningRoomScreen token={token} />}
            />
            <Route path="/notification" element={<NotificationScreen />} />
            <Route path="/ai-playlists" element={<AIRoomScreen />} />
            <Route path="/curate-ai-playlists" element={<AiScreen />} />
            <Route path="/view-ai-playlist" element={<AiCuiratedPlaylist />} />
            <Route path="/manageplaylist" element={<ManagePlayListScreen />} />
            <Route path="/profile" element={<ProfileScreen />} />
            <Route path="/subscription" element={<SubscriptionScreen />} />
            <Route path="/view-searched-ai-playlist" element={<SearchedAiCuiratedPlaylist />} />
            <Route path="/shared-playlist/:sharedPlaylistId" element={<ViewSharedPlaylist />} />
          </Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
