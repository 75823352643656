/* eslint-disable react/style-prop-object */
import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { ScreenTitleContainer } from "./ProfileScreen";
import PlaceholderImg from "../assets/placeholder.png";
import { useParams, useNavigate } from "react-router-dom";
import {
  CopyIcon,
  HomePlayIcon,
  LikeIcon,
  ShareMusicIcon,
  TracksPause,
  TracksPlay,
  TracksPlayColor,
} from "icons";
import { Layout } from "components/Layout";
import { useSelector } from "react-redux";
import { selectCurrentUser } from "features/auth/authSlice";
import { useFetchSharedPlaylistQuery } from "features/auth/authApiSlice";
import cookies from "js-cookie";
import { LoadingScreen } from "components/common/LoadingScreen";
import { BgOverlay } from "./HomeScreen";
import ProgressBottomModal from "components/Playlist/ProgressBottomModal";
import CompletedBottomModal from "components/Playlist/CompletedBottomModal";
import toast, { Toaster } from "react-hot-toast";
import { useDispatch } from "react-redux";
import {
  clearTotalTracks,
  totalTracks,
} from "features/tracks/tracksTotalSlice";
import { clearFailedTracks } from "features/tracks/failedTracksSlice";
import { clearCopied, copied } from "features/tracks/copiedTracksSlice";
import HomeCopyInitiate from "components/Home/CopyInitiate";
import { clearTracks } from "features/tracks/tracksSlice";
import { OutputType } from "@types";
import { PlaylistType } from "types";
import SelectPlaylistBottomModal from "components/ManagePlaylist/DestinationPlatformSharedPlaylistModal";
import { addTrackToLibrary, deleteTrackToLibrary } from "services/spotify";
import { MusicKitContext } from "components/MusicKitController";
import OneTapCopyAndPlayModal from "components/ManagePlaylist/OneTapCopyAndPlay";

interface LikeButtonProps {
  isLiked: boolean;
}


const ViewSharedPlaylist = () => {
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const params = useParams();
  const { sharedPlaylistId } = params;
  const { data } = useFetchSharedPlaylistQuery(sharedPlaylistId);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [copyPlaylistOngoing, setCopyPlaylistOngoing] =
    useState<boolean>(false);
  const [syncPlaylistOngoing, setSyncPlaylistOngoing] =
    useState<boolean>(false);
  const [actionCompleted, setActionCompleted] = useState<boolean>(false);
  const [initiateCopy, setInitiateCopy] = useState<boolean | null>(null);
  const [handleCopy, setHandleCopy] = useState<boolean | null>(null);
  const [hideModal, setHideModal] = useState<boolean>(false);
  const dispatch = useDispatch();
  const [playListArr, setPlayListArr] = useState<OutputType>([]);
  const [progress, setProgress] = useState<number>(0);
  const [seeDetails, setSeeDetails] = useState<boolean>(true);
  const totalCopied = useSelector(copied);
  const totalTrackss = useSelector(totalTracks);
  const [clickedItems, setClickedItems] = useState<PlaylistType[]>([]);
  const [selectedTracks, setSelectedTracks] = useState<any[]>([]);
  const [isLiked, setIsLiked] = useState(false);
  const [likedTracks, setLikedTracks] = useState<string[]>([]);
  const [currentTrackId, setCurrentTrackId] = useState("");
  const isTrackLiked = (trackId: string) => likedTracks.includes(trackId);
  const [initiateOneTapCopy, setInitiateOneTapCopy] = useState<boolean | null>(
    null
  );
  const [oneTapOngoing, setOneTapOngoing] = useState<boolean>(false);
  const [currentAudio, setCurrentAudio] = useState<any>(null);
  const [audioChanged, setAudioChanged] = useState<string>("pause");
  function toggleAudioPlayback(audioUrl: string) {
    if (currentAudio && currentAudio.src === audioUrl) {
      // If the same URL, toggle play/pause
      if (currentAudio.paused) {
        currentAudio.play();
        setAudioChanged("play");
      } else {
        currentAudio.pause();
        setAudioChanged("pause");
      }
    } else {
      // Otherwise, create a new Audio instance
      if (currentAudio) {
        setAudioChanged("pause");
        currentAudio.pause(); // Pause the previous audio
      }
      const newAudio = new Audio(audioUrl);
      setCurrentAudio(newAudio);
      newAudio.play();
      setAudioChanged("play");
    }
  }


  const context = useContext(MusicKitContext);

  useEffect(() => {
    const storedLikedTracks = localStorage.getItem("likedTracks");
    const likedTracks = storedLikedTracks ? JSON.parse(storedLikedTracks) : [];
    setLikedTracks(likedTracks);
    if (likedTracks.includes(currentTrackId)) {
      setIsLiked(true);
    } else {
      setIsLiked(false);
    }
  }, []);


  const handleHideModals = () => {
    setHideModal(true);
  };
  const appType = user?.primaryStreamingService;
  const handleHideModal = () => {
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    setInitiateCopy(false);
    setHandleCopy(false);
  };

  const closeCopyPlaylistModal = () => {
    setCopyPlaylistOngoing(!copyPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
    setHideModal(false);
  };

  const closeSyncPlaylistModal = () => {
    setSyncPlaylistOngoing(!syncPlaylistOngoing);
    dispatch(clearCopied());
    dispatch(clearFailedTracks());
    dispatch(clearTotalTracks());
    dispatch(clearTracks());
  };

  const selectPlaylistToBeCopied = (name: string, id: string) => {
    setClickedItems([
      {
        playlistName: name,
        playlistId: selectedTracks.length > 0 ? undefined : (id as string),
        selectedTracks: selectedTracks.length > 0 ? selectedTracks : undefined,
      },
    ]);
    setInitiateCopy(true);
  };

  const OneTapCopyAndPlay = (name: string, id: string) => {
    setClickedItems([
      {
        playlistName: name,
        playlistId: user.primaryStreamingService ===
          'apple' ? id : "",
        playlistImage: data?.playlistBannerUrl,
        selectedTracks: selectedTracks.length > 0 ? selectedTracks : undefined,
      },
    ]);
    setInitiateOneTapCopy(true);
  };


  useEffect(() => {
    setSelectedTracks(data?.tracks)
  }, [data]);

  useEffect(() => {
    if (totalCopied !== 0 && totalTrackss !== 0) {
      const percentageComplete = (totalCopied / totalTrackss) * 100;
      setProgress(percentageComplete);
    }
    console.log(
      totalCopied,
      totalTrackss,
      (totalCopied / totalTrackss) * 100,
      "totalCopied, totalTrackss,percentageComplete"
    );
  }, [totalCopied, totalTrackss]);
  const toggleLike = (trackId: string) => {
    setCurrentTrackId(trackId);

    // Check if the track is already liked
    const isTrackLiked = likedTracks.includes(trackId);

    // Get current liked tracks from local storage
    const storedLikedTracks = localStorage.getItem("likedTracks");
    let updatedLikedTracks = storedLikedTracks
      ? JSON.parse(storedLikedTracks)
      : [];

    if (isTrackLiked) {
      // Unlike the track
      deleteTrackToLibrary(trackId);
      updatedLikedTracks = likedTracks.filter((id) => id !== trackId);
    } else {
      // Like the track
      addTrackToLibrary(trackId);
      updatedLikedTracks = [...likedTracks, trackId];
    }

    // Update state and localStorage
    setLikedTracks(updatedLikedTracks);
    localStorage.setItem("likedTracks", JSON.stringify(updatedLikedTracks));
  };

  const addAppleTrackToLibrary = async (trackId: string) => {
    setCurrentTrackId(trackId);

    // Check if the track is already liked
    const isTrackLiked = likedTracks.includes(trackId);

    // Get current liked tracks from local storage
    const storedLikedTracks = localStorage.getItem("likedTracks");
    let updatedLikedTracks = storedLikedTracks
      ? JSON.parse(storedLikedTracks)
      : [];

    if (isTrackLiked) {
      // Unlike the track
      const tracks =
        context && (await context?.addAppleTrackToLibrary(trackId));
      if (tracks.status == 202) {
        toast.success("Track added to library");
        updatedLikedTracks = likedTracks.filter((id) => id !== trackId);
      } else {
        toast.error("Something went wrong!");
      }
    }
    // Update state and localStorage
    setLikedTracks(updatedLikedTracks);
    localStorage.setItem("likedTracks", JSON.stringify(updatedLikedTracks));
  };

  const addATrackToLibrary = (trackId: any) => {
    console.log(trackId)
    switch (appType) {
      case "spotify":
        toggleLike(trackId);
        break;
      case "apple":
        addAppleTrackToLibrary(trackId);
        break;
      case "library-playlists":
        addAppleTrackToLibrary(trackId);
        break;
      default:
        break;
    }
  };

  if (!loading) return <LoadingScreen />;

  const shareMusic = () => {
    const url = `https://app.playamusic.io/shared-playlist/${sharedPlaylistId}`;
    if (navigator.share) {
      navigator.share({
        title: "Shared Playlist",
        text: "Check out this playlist I shared!",
        url: url,
      });
    }
  };

  return (
    <Layout>
      <ViewPlaylistScreenContainer>
        <ScreenTitleContainer>
          {/* <Search placeholder="Search playlist" /> */}
        </ScreenTitleContainer>

        <CurrentSongCard>
          <PlaylistImage>
            <div
              onClick={() => OneTapCopyAndPlay(data?.playlistName, data?.playlistId)}
            >
              <HomePlayIcon />
            </div>
            <img
              src={data?.playlistBannerUrl ?? PlaceholderImg}
              alt="track cover"
            />
            <PlaylistOverlay></PlaylistOverlay>
          </PlaylistImage>

          <p>{data?.playlistName}</p>
          <span>{data?.tracks.length} songs</span>
        </CurrentSongCard>

        {data?.tracks && (
          <ListenerOptionsCard>
            <div
              onClick={() =>
                selectPlaylistToBeCopied(data?.playlistName, data?.playlistId)
              }
            >
              <CopyIcon />
              <p>
                Copy
              </p>
            </div>

            <div onClick={shareMusic}>
              <ShareMusicIcon />
              <p>Share</p>
            </div>
          </ListenerOptionsCard>
        )}

        {/* {loading && <LoadingScreen />} */}
        {loading && (
          <SongStackContainer>
            {data &&
              data?.tracks?.length > 0 &&
              data?.tracks.map((item: any) => {
                return (
                  <div key={item?.track?._id ?? item._id.toString()}>
                    <div>
                      <ImageFlex>
                        <div
                          onClick={
                            item?.preview_url
                              ? () => toggleAudioPlayback(item?.preview_url)
                              : () => null
                          }
                        >
                          {item?.preview_url && item?.preview_url !== "no preview" ? (
                            <>
                              {currentAudio &&
                                currentAudio.src === item?.preview_url &&
                                !currentAudio.paused &&
                                audioChanged === "play" ? (
                                <TracksPause />
                              ) : (
                                <TracksPlay />
                              )}
                            </>
                          ) : (
                            <TracksPlayColor color="#ccc" />
                          )}
                        </div>
                        <img
                          src={
                            data.streamingService !== "apple"
                              ? item.image_url ?? PlaceholderImg
                              : PlaceholderImg
                          }
                          alt="track cover"
                        />
                      </ImageFlex>
                      <div>
                        <p>{item?.name}</p>
                        <span>{item?.artist}</span>
                      </div>
                    </div>

                    <Actions>
                      {/* <div
                      style={{ width: 40 }}
                      onClick={
                        item?.preview_url
                          ? () => toggleAudioPlayback(item?.preview_url)
                          : () => null
                      }
                    >
                      {item?.preview_url &&
                      item?.preview_url !== "no preview" ? (
                        <>
                          {currentAudio &&
                          currentAudio.src === item?.preview_url &&
                          !currentAudio.paused &&
                          audioChanged === "play" ? (
                            <PauseIcon />
                          ) : (
                            <PlayIcon />
                          )}
                        </>
                      ) : (
                        <PlayColorIcon color="#ccc" />
                      )}
                    </div> */}
                      {/* <div onClick={() => selectTracksToBeCopied(item._id)}>
                      {selectedTracks.findIndex(
                        (track) => track._id === item._id
                      ) !== -1 ? (
                        <LikeIcon color="red" />
                      ) : (
                        <LikeIcon />
                      )}
                    </div> */}
                      {appType == "spotify" && (
                        <LikeButton
                          onClick={() => addATrackToLibrary(item?.track_id)}
                          isLiked={isTrackLiked(item?.track_id)}
                        >
                          {isTrackLiked(item?.track_id) ? (
                            <LikeIcon />
                          ) : (
                            <LikeIcon />
                          )}
                        </LikeButton>
                      )}
                    </Actions>
                  </div>
                );
              })}
          </SongStackContainer>
        )}

        {initiateCopy && (
          <>
            <HomeCopyInitiate
              closeModal={() => handleHideModal()}
              callBack={setHandleCopy}
            />
            <BgOverlay />
          </>
        )}

        {handleCopy && !actionCompleted && (
          <>
            <SelectPlaylistBottomModal
              clickedItems={clickedItems}
              setClickedItems={setClickedItems}
              onGoingState={setCopyPlaylistOngoing}
              action="Copy"
              closeModal={handleHideModal}
              source={data?.streamingService}
              playListArr={playListArr}
              actionCompleted={setActionCompleted}
            />
            <BgOverlay />
          </>
        )}


        {initiateOneTapCopy && (
          <>
            <OneTapCopyAndPlayModal
              clickedItems={clickedItems}
              setClickedItems={setClickedItems}
              onGoing={oneTapOngoing}
              playlistName={data.playlistName}
              onGoingState={setOneTapOngoing}
              action="Copy"
              closeModal={closeCopyPlaylistModal}
              source={data?.streamingService}
              playListArr={playListArr}
              actionCompleted={setActionCompleted}
              progress={progress}
            />
            <BgOverlay />
          </>
        )}

        {(syncPlaylistOngoing || copyPlaylistOngoing) && !hideModal && (
          <>
            <ProgressBottomModal
              title={
                copyPlaylistOngoing
                  ? "Copy in progress, please wait..."
                  : "Sync in progress..."
              }
              bodyText={
                copyPlaylistOngoing
                  ? `Copied ${totalCopied} of ${totalTrackss} songs`
                  : "Syncing 10 of 100 songs to your playlist"
              }
              closeModal={
                syncPlaylistOngoing
                  ? closeSyncPlaylistModal
                  : closeCopyPlaylistModal
              }
              actionBtn="Hide"
              callBack={handleHideModals}
              exitBtn="Cancel"
              icontype="copy"
              progress={progress}
            />
            <BgOverlay />
          </>
        )}

        {actionCompleted && (
          <>
            <CompletedBottomModal
              title="We are done copying your tracks 🤩"
              numberOfTracks={totalTrackss}
              totalNumOfCopiedTracks={totalCopied}
              seeDetails={seeDetails}
              setSeeDetails={setSeeDetails}
              closeModal={() => {
                setActionCompleted(false);
                dispatch(clearCopied());
                dispatch(clearFailedTracks());
                dispatch(clearTotalTracks());
                dispatch(clearTracks());
              }}
            />
            <BgOverlay />
          </>
        )}
      </ViewPlaylistScreenContainer>
      <Toaster />
    </Layout>
  );
};

export default ViewSharedPlaylist;

const ViewPlaylistScreenContainer = styled.div``;

const ImageFlex = styled.section`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const PlaylistImage = styled.section`
  position: relative;
  height: 100%;
  div {
    position: absolute;
    top: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
    height: 100%;
    z-index: 100;
  }
`;

const PlaylistOverlay = styled.span`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(6, 22, 30, 1) 60%
  );
  opacity: 0.5;
  border-radius: 16px;
  z-index: 0;
`;


const SongStackContainer = styled.div`
  margin-top: 20px;
  overflow: auto;
  height: 100vh;

  div {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    margin: 5px 0;
    padding: 4px 6px;

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      border: 0px;
      img {
        width: 30px;
        height: 30px;
        margin-right: 10px;
      }
      div {
        display: flex;
        flex-direction: column;
        align-items: start;
        border: 0px;
        p {
          font-size: 12px;
          color: #1f1f1f;
          font-weight: 700;
        }
        span {
          font-size: 10px;
          color: #8c8c8c;
          font-weight: 500;
          display: block;
        }
      }
    }
  }
`;

const CurrentSongCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 5%;

  img {
    width: 150px;
    height: 150px;
    border-radius: 22px;
  }
  p {
    font-size: 16px;
    color: #1f1f1f;
    font-weight: 700;
    margin: 5px auto;
  }
  span {
    font-size: 12px;
    color: #8c8c8c;
    display: block;
    font-weight: 500;
  }
`;

const Actions = styled.span`
  display: flex;
  align-items: center;
  gap: 3px;
`;

const ListenerOptionsCard = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 10px 0;

  div {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0 20px;

    p {
      font-size: 12px;
      color: #1f1f1f;
      font-weight: 400;
      padding-left: 8px;
    }
  }
`;

const LikeButton = styled.div<LikeButtonProps>`
  cursor: pointer;
  transition: transform 0.2s ease;

  &:active {
    transform: scale(1.2);
  }

  svg {
    fill: ${({ isLiked }) => (isLiked ? "#008135" : "tansparent")};
    stroke: #008135;
    transition: fill 0.2s ease;
    width: 30px;
    height: 34px;
  }
`;