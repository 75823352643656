import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { BackIcon } from "icons/BackIcon";
import styled from "styled-components";
import { ConnectAccountButton } from "components/ConnectAccountButton";
import {
  AppleMusicIcon,
  AudiomacIcon,
  SportifyIcon,
  YoutubeMusicIcon,
} from "icons/musicServicesIcon";
import { Layout } from "components/Layout";
import {
  useChangeMusicMutation,
  useConnectAppleMusicMutation,
  useDisconnectMusicMutation,
  useGetUserDetailsQuery,
  useRefreshMutation,
} from "features/auth/authApiSlice";
import {
  selectCurrentUser,
  setAuthUser,
  setCredentials,
} from "features/auth/authSlice";
import { MusicKitContext } from "components/MusicKitController";
import { LoadingScreen } from "components/common/LoadingScreen";
import { BgOverlay } from "./HomeScreen";
import BottomModalTwo from "components/Home/BottomModalTwo";
import { PlayaToast } from "utils";
import cookies from "js-cookie";
import { LOCALSTORAGE_KEYS } from "services/spotify";
import { IUser } from "@types";
import axios from "axios";
import { useDispatch } from "react-redux";
import toast from "react-hot-toast";
import { GOOGLE_LOCALSTORAGE_VALUES } from "services/youtube";
const ConnectAccountsScreen = () => {
  const context = useContext(MusicKitContext);
  const dispatch = useDispatch();
  const [connectAppleMusic, { isLoading, data: connectAppleData }] =
    useConnectAppleMusicMutation();
  const [
    disconnectMusic,
    { isLoading: disconnectMusicLoading, data: disconnectData },
  ] = useDisconnectMusicMutation();
  const [
    changeMusic,
    {
      isLoading: changeMusicLoading,
      data: changeData,
      error: changeMusicError,
    },
  ] = useChangeMusicMutation();
  const [refresh] = useRefreshMutation();
  // const user = useSelector(selectCurrentUser);
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user") as string);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [priamryApp, setPrimaryApp] = useState<string>(
    user?.primaryStreamingService as string
  );

  const navigate = useNavigate();
  const [localUser, setLocalUser] = useState<IUser>(
    userr ?? JSON.parse(cookies.get("playa_user") as string)
  );
  const [searchParams] = useSearchParams();
  const queryParam = searchParams.get("errorConnect");

  const location = useLocation();

  const {
    data: userData,
    error,
    isLoading: getUserLoading,
  } = useGetUserDetailsQuery("/auth/user-details");


  // Now use `location` safely without ESLint issues
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorConnect = params.get("errorConnect");
    if (errorConnect) {
      toast.error(decodeURIComponent(errorConnect));
      navigate(location.pathname, { replace: true });
    }
  }, [location]);
  // useEffect(() => {
  //   const userrr = cookies.get("playa_user");
  //   setLocalUser(JSON.parse(userrr) as IUser);
  // }, []);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BACKEND_URL}/auth/user-details`,
          {
            headers: {
              Authorization: `Bearer ${cookies.get("playa_access_token")}`,
            },
          }
        );
        console.log(response.data, "daata- user data---");
        // setLocalUser(response.data);
              cookies.set("playa_user", JSON.stringify(response.data), { expires: 7 });
        
        setLocalUser(response.data);
        return { status: 200 };
      } catch (error) {
        console.log(error);
        return { status: 503 };
      }
    };
    fetchUser();
  }, [userData, changeData, priamryApp, disconnectData, connectAppleData]);

  const authWithSpotify = () => {
    const token = cookies.get("playa_access_token"); // Retrieve the token from cookies
    if (!token) {
      console.error("Token not found! User might not be authenticated.");
      return;
    }

    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/login/spotify?state=${encodeURIComponent(token)}`,
      "_self"
    );
  };


  const authWithGoogle = () => {
    const token = cookies.get("playa_access_token"); // Retrieve the JWT token
    if (!token) {
      console.error("Token not found! User might not be authenticated.");
      return;
    }

    window.open(
      `${process.env.REACT_APP_BACKEND_URL}/auth/google?state=${encodeURIComponent(token)}`,
      "_self"
    );
  };

  const handleConnectWithApple = async () => {
    const instance = context?.instance;
    try {
      const result = await instance?.authorize();
      if (!result) {
        return;
      }
      const res: any = await connectAppleMusic({ accessToken: result });

      if (res?.data?.status === "success") {
        await refresh("");

        PlayaToast.success({ msg: res?.data?.message });
      } else {
        PlayaToast.error({ msg: res?.error?.data?.message });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const changeStreamingService = async () => {
    const res: any = await changeMusic({ service: priamryApp });
    if (res?.data?.status === "success") {
      await refresh("");
      setLocalUser((prev) => ({
        ...prev,
        primaryStreamingService: priamryApp,
      }));

      PlayaToast.success({ msg: res?.data?.message });
    } else {
      PlayaToast.error({ msg: res?.error?.data?.message });
    }
    setOpenModal(false);
  };
  //
  const disconnectStreamingService = async () => {
    const res: any = await disconnectMusic({ service: priamryApp });
    if (res?.data?.message.includes("disconnected successfully")) {
      await refresh("");
      if (priamryApp === "youtube") {
        setLocalUser((prev) => ({
          ...prev,

          isGoogleConnected: false,
        }));
      }
      if (priamryApp === "apple") {
        setLocalUser((prev) => ({
          ...prev,

          isAppleConnected: false,
        }));
      }
      if (priamryApp === "spotify") {
        setLocalUser((prev) => ({
          ...prev,

          isSpotifyConnected: false,
        }));
        localStorage.setItem(LOCALSTORAGE_KEYS.accessToken, "");

        localStorage.setItem(LOCALSTORAGE_KEYS.timestamp, "");
        localStorage.setItem(LOCALSTORAGE_KEYS.expireTime, "");

        localStorage.setItem(LOCALSTORAGE_KEYS.refreshToken, "");
      }

      PlayaToast.success({ msg: res?.data?.message });
    } else {
      PlayaToast.error({ msg: res?.error?.data?.message });
    }
    setOpenModal(false);
  };

  const handleModal = (app: string) => {
    setPrimaryApp(app);
    setOpenModal(true);
  };

  const accountArr = [
    {
      app: "spotify",
      icon: <SportifyIcon />,
      callback: authWithSpotify,
      isConnected: localUser?.isSpotifyConnected,
      isPrimary: localUser?.primaryStreamingService === "spotify",
    },
    {
      app: "apple",
      icon: <AppleMusicIcon />,
      callback: handleConnectWithApple,
      isConnected: localUser?.isAppleConnected,
      isPrimary: localUser?.primaryStreamingService === "apple",
    },
    {
      app: "youtube",
      icon: <YoutubeMusicIcon />,
      callback: authWithGoogle,
      isConnected:
        GOOGLE_LOCALSTORAGE_VALUES.refreshToken === "undefined" &&
          !localUser?.googleRefreshToken
          ? false
          : localUser?.isGoogleConnected,
      isPrimary: localUser?.primaryStreamingService === "youtube",
    },
    // {
    //   app: "audiomac",
    //   icon: <AudiomacIcon />,
    //   callback: () => null,
    //   isConnected: false,
    //   isPrimary: localUser?.primaryStreamingService === "audiomac",
    // },
  ];
  return (
    <Layout>
      {disconnectMusicLoading && <LoadingScreen />}
      <ConnectAccountsWrapper>
        <div className="connectedAccountHeader">
          <div onClick={() => navigate(-1)}>
            <BackIcon />
          </div>
          <h5 className="headerDescription">Connected Accounts</h5>
        </div>
        <div className="connectedAccountsContainer">
          {accountArr.map((acc) => {
            return (
              <div
                key={acc.app}
                onClick={
                  acc.isConnected
                    ? () => handleModal(acc.app)
                    : () => acc.callback()
                }
              >
                <ConnectAccountButton
                  description="Connect with"
                  serviceIcon={acc.icon}
                  isConnected={acc.isConnected}
                  isPrimary={acc.isPrimary}
                />
              </div>
            );
          })}
        </div>
      </ConnectAccountsWrapper>
      {openModal ? (
        <>
          <BottomModalTwo
            closeModal={() => setOpenModal(false)}
            mainText={
              user?.primaryStreamingService === priamryApp
                ? ""
                : `Make primary account`
            }
            callBack={changeStreamingService}
            callBackTwo={disconnectStreamingService}
            app={priamryApp}
            mainTextTwo={`Disconnect`}
            closeText="Close"
          />
          <BgOverlay />
        </>
      ) : null}
    </Layout>
  );
};

export default ConnectAccountsScreen;

const ConnectAccountsWrapper = styled.section`
  .connectedAccountHeader {
    display: flex;
    align-items: center;
  }

  .connectedAccountsContainer {
    display: flex;
    flex-direction: column;
    /* align-items: center;  */
    gap: 10px;
    margin-top: 30px;
  }
  .headerDescription {
    margin: 0 auto;
  }
`;
