import { Outlet, Link } from "react-router-dom";
import { useContext, useEffect, useRef, useState } from "react";
import { useRefreshMutation } from "./authApiSlice";
import usePersist from "hooks/usePersist";
import { useSelector } from "react-redux";
import { selectCurrentToken, selectCurrentUser } from "./authSlice";
import PulseLoader from "react-spinners/PulseLoader";
import { SocketContext } from "components/socket";
import { useDispatch } from "react-redux";
import { setRooms } from "features/rooms/roomsSlice";
import cookies from "js-cookie";

const PersistLogin = () => {
  const [persist] = usePersist();
  const token = localStorage.getItem("playa_access_token");
  const tokenn = token === null ? cookies.get("playa_access_token") : token;
  const effectRan = useRef(false);

  // const user = useSelector(selectCurrentUser)
  const userr = useSelector(selectCurrentUser);
  const user =
    userr !== null ? userr : JSON.parse(cookies.get("playa_user"));
  const dispatch = useDispatch()

  const [trueSuccess, setTrueSuccess] = useState(false);

  const [refresh, { isUninitialized, isLoading, isSuccess, isError, error }] =
    useRefreshMutation();

  const socket = useContext(SocketContext)

  useEffect(() => {
    if (effectRan.current === true || process.env.NODE_ENV !== "development") {
      // React 18 Strict Mode

      const verifyRefreshToken = async () => {
        // console.log("verifying refresh token");
        try {
          //const response =
          await refresh();
          //const { accessToken } = response.data

          setTrueSuccess(true);
        } catch (err) {
          console.error(err);
        }
      };

      if (!tokenn && persist) verifyRefreshToken();
    }

    return () => (effectRan.current = true);

    // eslint-disable-next-line
  }, []);

  let content;
  if (!persist) {
    // persist: no
    // console.log("no persist");
    content = <Outlet />;
  } else if (isLoading) {
    //persist: yes, token: no
    // console.log("loading");
    content = <PulseLoader color={"#FFF"} />;
  } else if (isError) {
    //persist: yes, token: no
    console.log("error");
    content = (
      <p className="errmsg">
        {`${error?.data?.message} - `}
        <Link to="/login">Please login again</Link>.
      </p>
    );
  } else if (isSuccess && trueSuccess) {
    //persist: yes, token: yes
    // console.log("success");
    content = <Outlet />;
  } else if (tokenn && isUninitialized) {
    //persist: yes, token: yes
    // console.log("token and uninit");
    console.log(isUninitialized);
    content = <Outlet />;
  } else {
    content = <Outlet />;
  }

  return content;
};
export default PersistLogin;
